
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider, Grid, Paper, Stack, TextField, ThemeProvider, Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../layout/BottomNavigation';
import { resetResponseState, forgotPassword, setLoading, unsetLoading, } from '../../store/appAction';
import toastr from "toastr";
import { useNavigate } from 'react-router-dom';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { mainTheme } from '../../theme';
import agrecalcLogo from '../../assets/images/agrecalc-original_v2.png'
import agrecalcBackground from '../../assets/images/agrecalc-cloud-background_v2.jpg'

const ForgotPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  toastr.options = toastrCustomOptionsLonger;

  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const { successMsg, errorMsg, loading } = useSelector(state => state.common);

  useEffect(() => {
    return () => {
      dispatch(unsetLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg);
      dispatch(resetResponseState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  const handleSubmit = () => {
    if (username) {
      dispatch(setLoading());
      dispatch(forgotPassword(username));
    } else {
      !username && setUsernameError('Please enter the Email');
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    switch (e.target.name) {
      case 'username':
        setUsername(value);
        setUsernameError(false);
        break;
      default:
        break;
    }
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Grid
        container
        direction="column"
        item
      // sx={{
      //   backgroundImage: `url(${agrecalcBackground})`,
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundPosition: 'right'
      // }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            style={{ height: "100vh" }}

          >
            <Grid item xs={12} sm={12} md={5} sx={{ background: '#FFFFFF' }}>
              <Grid container direction='column'>
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs="auto" sx={{ textAlign: 'center' }} pt={2}>
                  <img
                    height={80}
                    src={agrecalcLogo}
                    alt="logo"
                  />
                </Grid>
                <Grid item>
                  <Paper sx={{ padding: 4, marginX: 'auto', width: '70%', borderRadius: 0 }}>
                    <Typography variant="h4" sx={{ ml: 2, mb: 2 }}>Forgot password?</Typography>
                    <Grid sx={{ padding: 2 }}>
                      <Typography variant="body2" sx={{ mb: 2 }}>A password reset link will be sent to your email to reset your password. If you don't get an email within a few minutes, please re-try.</Typography>
                    </Grid>
                    <Grid sx={{ padding: 2 }}>
                      <TextField
                        error={usernameError}
                        name='username'
                        type="text"
                        fullWidth
                        label="Email"
                        placeholder="Email"
                        helperText={usernameError ? usernameError : ""}
                        onChange={(e) => handleInputChange(e)}
                        value={username}
                      />
                    </Grid>
                    <Stack sx={{ padding: 2 }}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handleSubmit}>
                        Submit
                      </Button>
                    </Stack>
                    <Divider>Or</Divider>
                    <Stack sx={{ padding: 2 }}>
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => navigate('/auth/login')}>
                        Login
                      </Button>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item
              sm={0}
              md={7}
              sx={{
                backgroundImage: `url(${agrecalcBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right'
              }}
            >
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer fixed={true} />
    </ThemeProvider >
  )
}

export default ForgotPassword