import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import {
  Grid, Paper, List, FormControl, MenuItem
} from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormRowGroupTableRow, FormTableRow,
  FormTableCell, SectorTableTitleTypography, FormHeaderPaper,
  DataEntryTextField, FormDescriptionTypography, DataEntrySelect, NoMaxWidthTooltip
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ListSubheader from "@mui/material/ListSubheader";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from "lodash";
import { isEqual } from "lodash";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { addCommas, removeCommas } from "../../../helpers/stringFormatHelper";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import {
  resetResponseState, unsetLoading, setLoading, getGrasslandOptions,
  getGrasslandMetadata, resetGrasslandMetadata,
  getGrasslandFormData, setGrasslandFormData, saveGrasslandData
} from "../../../store/appAction";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';
import EmptyTableBody from '../../../components/Tables/EmptyTableBody';


const DataEntryTable = ({ sector, grasslandOptions, initialFormData, firstVisibleCellDetails, locked, index }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState({});
  const [sectorDataExt, setSectorDataExt] = useState({});

 
  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setGrasslandFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = removeCommas(e.target.value);
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value)) {
        toastr.warning(
          `This input only accepts values between ${addCommas(
            rule.min
          )} and ${addCommas(rule.max)}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = useCallback((
    e, groupId, rowId, cell
  ) => {
    let value = removeCommas(e.target.value);
    if (cell.dataType === 'TextField') {
      if (e.target.inputMode === "numeric") {
        let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (numericRegex.test(value)) {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = value;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = parseFloat(value);
          }));
        }
      }
    }

    if (cell.dataType === 'Select') {
      const value = e.target.value;

      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value === '' ? null : value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value === '' ? null : value;
      }));


    }
  }, []);

  const getCellContent = ({ groupId, rowId, cell, rowData, firstVisibleCellDetails, locked }) => {

    const getDropdownMenuItems = (mappingProperty) => {
      let items;
      switch (mappingProperty) {
        case 'GCuttingIntensityBefore':
        case 'GCuttingIntensityNow':
          items = [{ id: 0, name: 'Please Select' }, ...grasslandOptions.GrazingCuttingIntensityType];
          break;
        case 'LimingBefore':
        case 'LimingNow':
          items = [{ id: 0, name: 'Please Select' }, ...grasslandOptions.LimingType];
          break;  
        default:
          return null;
      }
    
      return items.map((option) => (
        option.name === 'Please Select' ?
        <MenuItem key={option.id} value={option.id}><em>{option.name}</em></MenuItem> :
        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
      ));
    };


    switch (cell.dataType) {
      case "TextField":
        return (
          <DataEntryTextField
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width: 100, textAlign: "center" }}
            value={(rowData && rowData[cell.mappingProperty]) || ''}
            onChange={(e) =>
              handleInputChange(e, groupId, rowId, cell)
            }
            onBlur={(e) => handleOnBlur(e, cell.validations)
            }
            disabled={cell?.readOnly}
          />
        );

      case 'Select':
        return (
          <FormControl sx={{ width: '100%', margin: 0 }}>
            <DataEntrySelect
              disabled={cell?.readOnly}
              value={rowData && rowData[cell.mappingProperty] !== null ?
                rowData[cell.mappingProperty] : 0}
              displayEmpty
              onChange={(e) => handleInputChange(e, groupId, rowId, cell)}
            >

              {getDropdownMenuItems(cell.mappingProperty)}

            </DataEntrySelect>
          </FormControl >
        );
      default:
        return <FormTableCell></FormTableCell>;
    }
  };

  return (
    <>
      {
        (sector.sectorGroups.groups || []).map((group) => (
          <>
            <FormTableRow > <FormTableCell colSpan={20}> <SectorTableTitleTypography style={{ margin: '0 !important', padding: '0 !important' }} variant='body1'>{sector.title} - {group.title}</SectorTableTitleTypography>  </FormTableCell>  </FormTableRow>
            {
              group.rows.map((row) => (
                <FormTableRow>
                  <FormTableCell >{row.title}</FormTableCell>
                  {row.cells.map((cell) => (
                    <FormTableCell sx={{ minWidth: cell.dataType === 'Select' ? '150px' : '' }}>
                      {sectorDataInt && cell.visible &&
                        getCellContent({
                          groupId: group.id,
                          rowId: row.id,
                          cell: cell,
                          rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                          isOtherSales: false,
                          firstVisibleCellDetails: firstVisibleCellDetails,
                          locked: locked
                        })}
                    </FormTableCell>
                  ))}
                </FormTableRow>
              ))
            }
          </>
        ))
      }
    </>
  );
};


const Grassland = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector((state) => state.grassland.metaData);
  const initialFormData = useSelector((state) => state.grassland.initialFormData);
  const grasslandOptions = useSelector((state) => state.grassland.grasslandOptions);

  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const {reportDetails: { reportName }} = useSelector(state => state.farmReport);

  const [formData, setFormData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
   window.scrollY = 0;
    dispatch(setLoading());
    dispatch(getGrasslandOptions());
    dispatch(getGrasslandMetadata({ reportId }));
    dispatch(getGrasslandFormData({ reportId }));
    dispatch(resetResponseState());

    return () => {
      dispatch(resetGrasslandMetadata());
    }
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 4000);
  }, [initialFormData]);


  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/soil-carbon/soil-properties');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const isFormUpdated = () => {
    const grasslandData = store.getState().grassland;
    const isUpdated = !isEqual(grasslandData?.initialFormData, grasslandData?.currentFormData);
    return isUpdated;
  }

  const handleSaveClick = (e) => {
    if (!isFormUpdated()) {
      return;
    }
    dispatch(setLoading());
    dispatch(saveGrasslandData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      navigate('/data-entry/soil-carbon/soil-properties');
    }
    else {
      dispatch(setLoading());
      dispatch(saveGrasslandData({ reportId }));
    }
  }

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
     window.scrollY = 0;
      dispatch(getGrasslandMetadata({ reportId }));
      dispatch(getGrasslandFormData({ reportId }));
    }
  };

  const renderCropResidesTooltip = () => {
    const text = `
    <div>Low residue crops include most root and </div>
    <div>leafy vegetables, processing peas and  </div>
    <div>processing beans. If low residue crops are</div>
    <div>grown over more than a third of a rotation, </div>
    <div>it is classified as a low residue rotation</div>
    `;
    return <div dangerouslySetInnerHTML={{ __html: text }} style={{ padding: '5px' }} />
  };


  const renderChangeYearTooltip = () => {
    const text = `
    <div>If multiple management changes have occurred in the past 20 years,  </div>
    <div>please enter the average year of the changes. For example,  </div>
    <div>if you changed grazing intensity in 2005 and changed your  </div>
    <div> nitrogen and liming practices in 2009,you would enter 2008 </div>
    `;
    return <div dangerouslySetInnerHTML={{ __html: text }} style={{ padding: '5px' }} />
  };

  const renderGrazingTooltip = () => {
    const text = `
    <div>Livestock units are a good guideline for the grazing intensity of a field. </div>
    <div>They are used for the below classification: </div>
    <div></div>
    <div>-2.5 LU/ha is very intensive </div>
    <div>-2.0 LU/ha is intensive  </div>
    <div>-1.5 LU/ha is moderate  </div>
    <div>-1.0 LU/ha is extensive  </div>
    <div>-0.5 LU/ha is very extensive  </div>
    <div></div>
    <div>For more guidance on calculating livestock units, </div>
    <div>you can consult the Farm	Management Handbook <a href="https://www.fas.scot/publication/farm-management-handbook-2023-24/">(https://www.fas.scot/publication/farm-management-handbook-2023-24/)</a></div>
    `;


    return <div dangerouslySetInnerHTML={{ __html: text }} style={{ padding: '5px' }} />
  };

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName} />
            <SelectedReport reportName={reportName} />
            <FormTitleTypography variant="h5" component="div">
              {"Grassland"}
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              If you have changed the way you manage your grassland in the past 20 years then it is likely there is ongoing change to your soil carbon stocks.
              If you haven't changed your management in the past 20 years then this section can be skipped. Please complete this section after finishing the Land and Crops and Livestock sections
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop: '0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSync>
                    <DataEntryButtonStack
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      handleModalResponse={handleModalResponse}
                      handleSaveClick={handleSaveClick}
                      handleNextClick={handleNextClick}
                    />
                </ScrollSync>
              </ListSubheader>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table
                  size="large"
                  aria-label="customized table"
                >
                  <TableHead>
                    <FormTableRow >
                      <FormTableCell rowSpan={3} style={{ position: 'sticky' }}></FormTableCell>
                      <FormTableCell rowSpan={2} colSpan={2} align="center"> {"Grazing/cutting intensity"}
                          <NoMaxWidthTooltip title={renderGrazingTooltip()}><InfoOutlinedIcon  color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' /></NoMaxWidthTooltip>
                      </FormTableCell>
                      <FormTableCell rowSpan={2} colSpan={2}> Reseeding interval (years) </FormTableCell>
                      <FormTableCell rowSpan={2} colSpan={2} > Nitrogen (kg/ha) </FormTableCell>
                      <FormTableCell rowSpan={2} colSpan={2} > Liming </FormTableCell>
                      <FormTableCell rowSpan={2} colSpan={2} > Clover in grass sward (%) </FormTableCell>

                    </FormTableRow>
                    <FormTableRow>
                    </FormTableRow>
                    <FormTableRow>

                      <FormTableCell > Before </FormTableCell>
                      <FormTableCell > Now </FormTableCell>
                      <FormTableCell > Before </FormTableCell>
                      <FormTableCell > Now </FormTableCell>
                      <FormTableCell > Before </FormTableCell>
                      <FormTableCell > Now </FormTableCell>
                      <FormTableCell > Before </FormTableCell>
                      <FormTableCell > Now </FormTableCell>
                      <FormTableCell > Before </FormTableCell>
                      <FormTableCell > Now </FormTableCell>


                    </FormTableRow>
                  </TableHead>

                  <TableBody>
                    { _.isEmpty(formData) &&  <EmptyTableBody title="data"/>  }
                    {initialFormData && formData && firstVisibleCellDetails && sectors.map((sector, index) => (
                      <>
                        <React.Fragment key={index}>
                          <DataEntryTable
                            key={sector.id}
                            sector={sector}
                            grasslandOptions={grasslandOptions}
                            initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                            firstVisibleCellDetails={firstVisibleCellDetails}
                            locked={props.locked}
                            index={index}
                          />
                        </React.Fragment>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
};

export default Grassland;
