import * as React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import BackButton from './BackButton';
import CancelModal from '../Modal/CancelModal';
import DeleteReportModal from "../Modal/DeleteReportModal";
import {CustomizedDivider} from "../CustomStyles/StyledComponents";

const DataEntryButtonStack = ({
    modalOpen, setModalOpen, handleModalResponse,
    handleSaveClick, handleNextClick, 
    handleDeleteModalResponse, setdeleteModalOpen, deleteModalOpen=false, deleteDisabled=true,
    divider=true
}) => {
    return (
        <>
        <Grid container direction="row" >
            <Grid item xs={2}>
                <BackButton />
            </Grid>
            <Grid item xs={10} sx={{ display:'flex', alignItems:'center' }}>
                <Stack spacing={2} sx={{ marginLeft: 'auto', float: 'right' }} direction="row">
                    <Button
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => setModalOpen(true)}>
                        Cancel
                    </Button>
                    {handleDeleteModalResponse && setdeleteModalOpen?
                        <Button 
                            disabled={deleteDisabled}
                            variant="outlined" sx={{ textTransform: 'none' }}
                            onClick={(e) => { setdeleteModalOpen(true);}}>
                            Delete
                        </Button>:''}
                    <Button variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={handleSaveClick}>
                        Save
                    </Button>
                    <Button variant="contained"
                        sx={{ textTransform: 'none' }}
                        className="activated"
                        onClick={handleNextClick}>
                        Next
                    </Button>
                </Stack>
            </Grid>
            <CancelModal isOpen={modalOpen} handleResponse={handleModalResponse} />
            <DeleteReportModal isOpen={deleteModalOpen} handleResponse={handleDeleteModalResponse} />
        </Grid>
        {divider?<CustomizedDivider/>:''}
        </>
    );
};

export default DataEntryButtonStack;

