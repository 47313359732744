
import {
  Backdrop,
  Button,
  Checkbox, CircularProgress, FormControlLabel, Grid, Link, Paper, Stack, TextField, ThemeProvider, Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../layout/BottomNavigation';
import { resetResponseState, resetAuthResponse, signIn, getUserInfo, getUserPermissions, setLoading, unsetLoading, getBackendVersion, getSiteAdminData } from '../../store/appAction';
import toastr from "toastr";
import { useLocation, useNavigate, } from 'react-router-dom';
import { toastrCustomOptions } from '../../constants/toastrOptions';
import { mainTheme } from '../../theme';
import agrecalcLogo from '../../assets/images/agrecalc-original_v2.png'
import agrecalcBackground from '../../assets/images/agrecalc-cloud-background_v2.jpg'
import { FARMER } from '../../constants/userRoles';
import queryString from 'query-string';

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  toastr.options = toastrCustomOptions;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const { isLoggedIn, user } = useSelector(state => state.auth);
  const { errorMsg, loading } = useSelector(state => state.common);
  const siteAdminDetails = useSelector(state => state.common.siteAdmin);
  const { returnUrl } = queryString.parse(location.search);
  useEffect(() => {
    dispatch(getBackendVersion());
    dispatch(getSiteAdminData());
    return () => {
      dispatch(getSiteAdminData());
      dispatch(resetAuthResponse());
      dispatch(unsetLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn || errorMsg) {
      dispatch(unsetLoading());
      errorMsg && toastr.error(errorMsg);
      dispatch(resetResponseState());
      if (!errorMsg && isLoggedIn) {
        //get user id, need to get this information using token! 
        dispatch(getUserInfo());
        //get the permission for the logged user
        dispatch(getUserPermissions())
        if (returnUrl)
          navigate(returnUrl);
        else if (user?.role.includes(FARMER))
          navigate('/farm-reports/list');
        else
          navigate('/admin/farm/list');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, errorMsg]);

  const handleSubmit = () => {
    if (username && password) {
      dispatch(setLoading());
      dispatch(signIn({ username, password, rememberMe }));
    } else {
      !username && setUsernameError(true);
      !password && setPasswordError(true);
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    switch (e.target.name) {
      case 'userName':
        setUsername(value);
        setUsernameError(false);
        break;
      case 'password':
        setPassword(value);
        setPasswordError(false);
        break;
      default:
        break;
    }
  }

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked)
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Grid
        container
        direction="column"
        item
      >
        <Grid item>
          {
            siteAdminDetails?.isBannerVisible === true && <div className="temp-site-down-banner" dangerouslySetInnerHTML={{ __html: siteAdminDetails?.bannerContent }}/>
          }
          <Grid
            container
            direction="row"
            style={{ height: "90vh" }}
          >
            <Grid item xs={12} sm={12} md={5} sx={{ background: '#FFFFFF' }}>
              <Grid container direction='column'>
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs="auto" sx={{ textAlign: 'center' }} pt={2}>
                  <img
                    height={80}
                    src={agrecalcLogo}
                    alt="logo"
                  />
                </Grid>
                <Grid item>
                  <Paper sx={{ padding: 4, marginX: 'auto', width: '70%', borderRadius: 0 }}>
                    <Typography variant="h4" sx={{ ml: 2, mb: 2 }}>Log in</Typography>
                    <Grid sx={{ padding: 2 }}>
                      <TextField
                        error={usernameError}
                        name='userName'
                        type="text"
                        fullWidth
                        label="Email Address"
                        placeholder="Email Address"
                        onChange={(e) => handleInputChange(e)}
                        value={username}
                      />
                    </Grid>
                    <Grid sx={{ padding: 2 }}>
                      <TextField
                        error={passwordError}
                        name='password'
                        type={"password"}
                        fullWidth
                        label="Password"
                        placeholder="Password"
                        variant="outlined"
                        onChange={(e) => handleInputChange(e)}
                        value={password}
                      />
                    </Grid>
                    <Grid sx={{ ml: 2 }}>
                      <FormControlLabel
                        value={rememberMe}
                        control={<Checkbox color="success" onChange={(e) => handleRememberMeChange(e)} />}
                        label="Please remember me"
                      />
                    </Grid>
                    <Stack sx={{ padding: 2 }}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handleSubmit}>
                        Log In
                      </Button>
                    </Stack>
                    <Grid pt={2} pl={3} mb={2}>
                      Forgot your password?
                      <Link pl={1}
                        sx={{ cursor: 'pointer' }}
                        underline="none"
                        onClick={() => navigate('/auth/forgot-password')}
                      >
                        Click here
                      </Link>
                    </Grid>
                    {/* <Divider>Or</Divider>
                    <Stack sx={{ padding: 2 }}>
                      <Button                       
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => navigate('/auth/register')}>
                        Register
                      </Button>
                    </Stack> */}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item
              sm={0}
              md={7}
              sx={{
                backgroundImage: `url(${agrecalcBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right'
              }}
            >
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer fixed={true} />
    </ThemeProvider >
  )
}

export default Login