import toastr from 'toastr';
import { toastrCustomOptionsLonger } from '../constants/toastrOptions';

export const getFirstVisibleCellDetailsInDataEntry = (data) => {
  for (const obj of data) {
    for (const group of obj?.sectorGroups?.groups || []) {
      for (const row of group?.rows || []) {
        const visibleCell = row.cells.find(cell => cell?.visible);
        if (visibleCell) {
          const { id: parentId, title: parentTitle } = obj || {};
          const { id: groupId, title: groupTitle } = group || {};
          const { id: rowId, title: rowTitle } = row || {};
          const { id: cellId, variant, dataType, mappingProperty } = visibleCell || {};
          return {
            parentId, parentTitle, groupId, groupTitle, rowId, rowTitle, cellId, variant, dataType, mappingProperty
          };
        }
      }
    }
  }
  return null;
}

export const LandAndCropsParams = Object.freeze({
  SoilCarbonParams: ['FractionOfLandConvertedInLast20Years',
    'LandUseBeforeConvertion',
    'GrazingSystem'
  ],
  OwnedAndSeasonalParams: ['OwnedAndTenantedLand','SeasonalLand'],

  isSoilCarbonParam: (param) => {
    return (LandAndCropsParams.SoilCarbonParams.includes(param))
  },

  isOwnedOrSeasonalParam: (param) => {
    return (LandAndCropsParams.OwnedAndSeasonalParams.includes(param))
  },
});




// Cell widths based on the following data retturned from the meta call:
// columns.cssClass and 
// sectors[A].sectorGroups.groups[X].rows[Y].cells.mappingProperty
const CELL_WIDTH_PCT = {
  RowTitle: 15,
  OwnedAndTenantedLand: 7, //OwnedAndTenantedLandTotal OwnedTenantedLandRYandB
  SeasonalLand: 7, //SeasonalLandTotal //SeasonalLandRYandB
  AnnualOccupancyOfSeasonalLand: 7,
  NumberOfYearsCropInTheGround: 7,
  FractionOfLandConvertedInLast20Years: 7,
  LandUseBeforeConvertion: 18,
  CloverInGrassSwardNow: 7,
  GrazingSystem: 25
};


export class CellWidthTracker {

  constructor(soilc = false, specials = CellWidthTracker.Default ) {
    this.cellsMap = new Map(Object.entries(CELL_WIDTH_PCT))
    // Remove the soil carbon entries if not enabled
    if (!soilc) {
      LandAndCropsParams.SoilCarbonParams.forEach(v => this.cellsMap.delete(v))
    }
    if (specials.length) {
      specials.forEach(m => {
        this.cellsMap.set(m.to, this.cellsMap.get(m.from));
        this.cellsMap.delete(m.from);
      })
    }
  }

  static get Default(){
    return [ ];
  }

  static get Total(){
    return [ {from:'OwnedAndTenantedLand',to:'OwnedAndTenantedLandTotal'},
             {from:'SeasonalLand',to:'SeasonalLandTotal'}];
  }

  static get Misc(){
    return[ {from:'OwnedAndTenantedLand',to:'OwnedTenantedLandRYandB'},
            {from:'SeasonalLand',to:'SeasonalLandRYandB'}]
  }

  popCellWPct(cell) {
    let cellW = this.cellsMap.get(cell);
    this.cellsMap.delete(cell);
    return (cellW + "%");
  }

  static getCellWPct(cell){ return `${CELL_WIDTH_PCT[cell]}%` }


  getRemainder() {
    let cellWTotal = [];
    this.cellsMap.forEach((w, k, m) =>
      cellWTotal.push({key:k, width:(w + "%")})
    );
    return cellWTotal;
  }
}

/**************************************************************************************/
/**
 * 
 */
const tmpSectorNameMap = new Map( [['Beef', 1],['Sheep', 2], ['Dairy', 3],['Pigs', 4],
  ['Poultry', 5], ['Grazing and Forage', 6], ['Combinable crops', 7], ['Other crops', 8],
  ['Potatoes, beet, and root vegetables', 9], ['Other vegetables', 10], ['Fruit', 11],
  ['Woodlands', 12], ['Hedges', 13], ['Other forage crops', 14]]);

const nycgCheck= (param, sector) => {
  let checkSectors = new Set([6,7,8,9,10,11,14]);
  let sectorID;
  switch(typeof(sector))
  {
    case 'string':
      sectorID = tmpSectorNameMap.get(sector.trim());
      break;
    case 'number':
      sectorID = Number.isInteger(sector)?sector:0
      break;
    default:
      sectorID = 0;
  }
  // If we can't find the sector we don't do the checks because 
  // not all fields can be checked. 
  return checkSectors.has(sectorID)?(param && param >0):true
}


/**************************************************************************************/
/**
 * 
 */
export const ParamValidator = Object.freeze({
  //map cell ids to check functions.
  cellCheckers: new Map([
    ['NumberOfYearsCropInTheGround', (param,sector) => nycgCheck(param,sector) ]
  ]),

    /**
   * Checks the param value is correct
   * @param {*} pName 
   * @param {*} paramvalue 
   * @returns true if valid, false otherwise. If the param is not foind it returns true.
   *          This is because it may be used in array maps with some fields not needing checking
   */
    isValid: (pName, pValue, sector) => {
      return pValue.visible? ParamValidator.isValidValue(pName, pValue.value, sector):true;
    },

    isValidValue: (paramName, paramValue, sector) => {
      let pCheckerFn = ParamValidator.cellCheckers.get(paramName)
      return pCheckerFn? pCheckerFn(paramValue,sector):true;
    }

});

/**************************************************************************************/
/**
 * 
 */
class ReportTree{
    /**
   * 
   * @param {*} sectorConfig 
   * @param {*} sectors 
   * @param {*} rptData 
   */
    constructor(sectorConfig, sectors, rptData) {
      // Merge the config info with the data so we can make 
      // suitable queries 
      this.rptData = this.depthFirstMerge(sectorConfig, sectors, rptData);
  
    }
  
    dfmCells(rptGrpValues,sInfoCells){
      let rptCellItems = {}
      sInfoCells.forEach((c)=>{
        rptCellItems[c.mappingProperty] = { value : rptGrpValues[c.mappingProperty], ...c }
      })
      return rptCellItems;
    }
  
    // Merge the acutual crop items
    dfmItems(sctGrpItems,rptGrpValues,secInfoItems){
      let rptItems=[];
      sctGrpItems.forEach((esItem) => {
        if (!esItem.isDeleted) {
          let rptEsiRptId = esItem.reportESItemId;
          let rptEsiId = esItem.enterpriseSectorItemId;
          let sItem = secInfoItems.find((si) => (si.id===rptEsiRptId));
          if (rptGrpValues[rptEsiRptId]) {
            let rptParamData = this.dfmCells(rptGrpValues[rptEsiRptId],sItem.cells)
            rptItems.push({ esiId: rptEsiId, title:sItem?.title, ...rptParamData });
          }
        }
      })
      return rptItems;
    }
   
    dfmGroups(sectGrp,rptGrpValues,sectInfoGrp){
      let rptGrps=[]
      sectGrp.forEach((esg) => {
        if (!esg.isDeleted) {
          let rptEsgId = esg.reportESGroupId;
          let rptEsgName = esg.enterpriseGroupName
          let sGrp=sectInfoGrp.find((sg)=>(sg.id===rptEsgId));
          if (rptGrpValues[rptEsgId]) {
            let rptItems= this.dfmItems(esg.sectorItemConfigurations, rptGrpValues[rptEsgId], sGrp.rows);
            rptGrps.push({  entSecGrpId: rptEsgId, esgName: rptEsgName,
                            title:sGrp?.title, items:rptItems})
          }
        }
      })
      return rptGrps;
    }
  
    /** Depth first copy and merge of 3 trees.
      * 
      * @param {*} sectorConfig from report details
      * @param {*} sectorInfo sector info from metadata call
      * @param {*} rptData data values 
      * @returns 
      */
    depthFirstMerge(sectorConfig, sectorInfo, rptData) {
      let mergedTree=[]; // List of EnterpriseSectors
      sectorConfig.forEach((entSector)=>{
        // for each sectorconfig that isn't deleted we find the corresponding 
        // data and sector info
        if(!entSector.isDeleted){
          let esRptID = entSector.reportEnterpriseSectorId;
          let esID= entSector.enterpriseSectorId;
          if(rptData[esRptID]){
            let sect= sectorInfo.find((s)=>(s.id===esRptID));
            let grps=this.dfmGroups(entSector.enterpriseGroupConfigurations,rptData[esRptID],sect.sectorGroups.groups);
            mergedTree.push({esID: esID, title:sect?.title, groups:grps })
          }
        }
      });
      return mergedTree;
    }

    /**
     * 
     * @param {*} handlerFn 
     */
    dfsIterator(handlerFn){
      this.rptData.forEach((s) => {
        s.groups.forEach((g) => {
          g.items.forEach((i) => {
            handlerFn(s,g,i);
          })
        })
      })
    }

    toString(){
      return JSON.stringify(this.rptData);
    }
  
}

/**************************************************************************************/
/**
 * 
 */
export class LandAndCropsDataChecker {
  /**
   * 
   * @param {*} sectorConfig 
   * @param {*} sectors 
   * @param {*} lacData 
   */
  constructor(sectorConfig, sectors, lacData) {
    // Merge the config info with the data so we can make 
    // suitable queries 
    this.lacData = new ReportTree(sectorConfig, sectors, lacData);

  }

  checkNumberYearsCropInGround() {
    let invalid = [];
    this.lacData.dfsIterator((s,g,i)=>{
      let nycName='NumberOfYearsCropInTheGround';
      if (!ParamValidator.isValid(nycName, i[nycName], s.esID)) {
        invalid.push(i.title)
      }
    })
    return invalid;

  }

  toString(){
    return this.lacData.toString();
  }

}

const tmpLivestockSectors = new Set(['Beef','Sheep','Dairy','Pigs','Poultry']);


class ManureTransfers {
  constructor(mtData) {
    this.mtData = mtData;
  }
  getMtPerSector() {
    //Add up the transferred manures into a map with the sector as key
    //and the solid and liquid percentages as data
    let mtPerSector = new Map(); // manure transfeers per livestock sector
    for (const groupsList of Object.values(this.mtData)) {
      for (const itemsList of Object.values(groupsList)) {
        for (const params of Object.values(itemsList)) {
          // Poultry is different and we need to remap
          if (params?.WithOrWithoutLitterPercentage) {
            let currValue = mtPerSector.get('Poultry');
            let pLmp = currValue?.LiquidManurePercentage
            let pSum = pLmp ? (pLmp + params.WithOrWithoutLitterPercentage)
              : params?.WithOrWithoutLitterPercentage;
            mtPerSector.set('Poultry', { SolidManurePercentage: pSum });
          }
          tmpLivestockSectors.forEach((s) => {
            // stored as sector-{SolidMpTotal, LiquicMpTotal}
            let paramNames = ['SolidManurePercentage', 'LiquidManurePercentage'];
            paramNames.forEach((p) => {
              let dataParamName = s + p;
              if (params[dataParamName]) {
                let currValue = mtPerSector.get(s);
                let pSum = currValue || {};
                pSum[p] = (currValue?.[p]) ? (currValue[p] + params[dataParamName]) : params[dataParamName];
                mtPerSector.set(s, pSum);
              }
            });
          });
        }
      }
    }
    return (mtPerSector);
  }
}

/**************************************************************************************/
/**
 * 
 */
export class ManureManagementDataChecker {
  /**
   * 
   * @param {*} sectorConfig 
   * @param {*} sectors 
   * @param {*} mmData 
   * @param {*} mtData 
   */
  constructor(sectorConfig, sectors, mmData, mtData) {
    // Merge the config info with the data so we can make 
    // suitable queries 
    this.mmData = new ReportTree(sectorConfig, sectors, mmData);
    this.mtransfers = new ManureTransfers(mtData);
  }

  checkManureTransfers() {
    let invalid = [];

    let mtPerSector = this.mtransfers.getMtPerSector();

    // Exported Manure is handled separately
    //let params2Check=['FmmAsSlurry','FmmAsFYM','FmmInPits','FmmWAD','FmmDeepBedding','FmmAsPoultryManure'];
    
    let liquids=['FmmAsSlurry', 'FmmInPits', 'FmmWAD']
    let solids=['FmmAsFYM','FmmDeepBedding','FmmAsPoultryManure']

    const sumFn = (pLst,data) =>{
      let sum = 0;
      pLst.forEach((mmv)=>
        {
          sum += Number.parseInt(data[mmv]?.value) || 0;
        })
      return sum;
    }

    // For each sector item (ewes, tups... ) add the liquids and add the solids.
    // If liquid or solid >0 and  export < 100 and manure transfer for the sector 
    //    (beef/sheep) is 0 on the liquid or solid then flag error.
    this.mmData.dfsIterator((s,g,i)=>{
      let sectorItemLiquids = sumFn(liquids,i);
      let sectorItemSolids = sumFn(solids,i);
      let exported = Number.parseInt(i['ManureExported']?.value) || 0;
      let mts = mtPerSector.get(s.title);
      let transferredPerSector = mts?.SolidManurePercentage || 0
                               + mts?.LiquidManurePercentage || 0;
     
      if ((sectorItemLiquids > 0 || sectorItemSolids > 0) 
          &&  exported <100 && transferredPerSector === 0){
           invalid.push(`${g.title}-${i.title}`);
      }

    });

    return invalid;

  }

  toString(){
    return this.mmData.toString();
  }

}

/**************************************************************************************/
/**
 * 
 */
const checkIfValidOrNotify = (executeOnValid, validateInputs, notificationLevel='Error') => {
  let isValid = validateInputs();

  if (isValid.status) {
    if (executeOnValid) executeOnValid()
  } else {
    switch (notificationLevel){
      case 'Error':
        toastr.error(isValid.message, "Data Checks Failed", toastrCustomOptionsLonger);
        break;
      case 'Warning':
      default:
        toastr.warning(isValid.message, "Data Checks Failed", toastrCustomOptionsLonger);
        if (executeOnValid) executeOnValid()
        break;          
    }
    return false;
  }
  return true;
}

/**************************************************************************************/
/**
 * 
 */
export const DataEntryChecker = Object.freeze({
  generateSaveHandler: (isFormUpdated, validateInputs, saveAction, notificationLevel='Error') => {
    return ((e) => {
      if (!isFormUpdated()) {
        toastr.success("There are no changes to save.");
      } else {
        checkIfValidOrNotify(saveAction, validateInputs,notificationLevel);
      }
    });
  },

  generateNextHandler: (isFormUpdated, validateInputs, saveAction, isLocked, navAction, setNextClicked, notificationLevel='Error') => {
    return ((e) => {
      let setNextClicked = (notificationLevel!=='Error');
      if (!isFormUpdated()) {
        // If we can't change the report there's no point in warning the user
        if (isLocked) {
          navAction();
        } else {
          // if the user can change the report then we still check if the data is valid
          checkIfValidOrNotify(navAction, validateInputs, notificationLevel);
          setNextClicked(setNextClicked);
        }
      } else if (!checkIfValidOrNotify(saveAction, validateInputs, notificationLevel)) {
        setNextClicked(setNextClicked);
      }
    });
  }
});