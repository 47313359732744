import { Button, Grid, Paper, Skeleton, Stack, Table, TableBody, TableContainer, TableHead, List, ListSubheader } from '@mui/material';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormHeaderPaper, FormRowGroupTableRow, FormTableCell, FormTableRow, FormTitleTypography, DataEntryTextField, SectorTitleTypography, FormDescriptionTypography } from '../../../components/CustomStyles/StyledComponents';
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import _ from "lodash";
import { isEqual } from "lodash";
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import produce from 'immer';
import {
    getAllocateRenewableElectricity,
    getRenewableElectricityFormData,
    getRenewableElectricityMetaData,
    resetRenewableElectricityMetaData,
    resetResponseState,
    saveRenewableElectricityData,
    setLoading,
    setRenewableElectricityFormData,
    setRenewableElectricityWholeFarmData,
    unsetLoading,
} from '../../../store/appAction';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
    RowTitle: "20%",
    Wind: "13%",
    SolarPV: "13%",
    Hydro: "13%",
    BiogasElectricity: "13%",
    ElectricityTotalProduced: "13%",
    ElectricityTotalUsed: "13%",
    GeneralCell: "13%"
};

const DataEntryTable = ({ sector, initialFormData, colGroups }) => {
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    const [sectorDataInt, setSectorDataInt] = useState(undefined);
    const [sectorDataExt, setSectorDataExt] = useState(undefined);

    useEffect(() => {
        setSectorDataInt(initialFormData);
        setSectorDataExt(initialFormData);
    }, [initialFormData]);

    useEffect(() => {
        if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
            dispatch(setRenewableElectricityFormData({
                sectorId: sector.id,
                formData: sectorDataExt
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectorDataExt]);

    const handleInputChange = useCallback((e, rowId, colId, rule) => {
        let value = removeCommas(e.target.value);
        if (e.target.inputMode === "numeric") {
            let numericRegex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
            if (numericRegex.test(value)) {
                if (rule.max < parseFloat(value || 0)) {
                    toastr.warning(`This input only accepts values between ${addCommas(rule.min
                    )} and ${addCommas(rule.max)}.  please enter the value again.`,
                        undefined,
                        toastrCustomOptionsLonger
                    );
                } else {
                    setSectorDataInt(produce((draft) => {
                        draft[rowId][colId] = value;
                    }));
                    setSectorDataExt(produce((draft) => {
                        draft[rowId][colId] = parseFloat(value);
                    }));
                }
            }
        } else {
            setSectorDataInt(produce((draft) => {
                draft[rowId][colId] = value;
            }));
            setSectorDataExt(produce((draft) => {
                draft[rowId][colId] = parseFloat(value);
            }));
        }
    }, []);

    const getCellContent = ({ rowId, cell, rowData }) => {
        switch (cell.dataType) {
            case "TextField":
                return (
                    <DataEntryTextField
                        size="small"
                        variant="outlined"
                        inputProps={{ inputMode: "numeric" }}
                        style={{ width: 125, textAlign: "center" }}
                        value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                rowId,
                                cell.mappingProperty,
                                cell.validations
                            )}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            {sector ?
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                    <SectorTitleTypography>{sector.title}</SectorTitleTypography>
                    <Fragment>
                        <Table>
                            {/* The following code segment has been commented out due to the requirement of AGC-977 */}
                            {/* <TableHead>
                                <FormRowGroupTableRow>
                                    <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} ></FormTableCell>
                                    {colGroups && colGroups.map((colGroup) =>
                                        <FormTableCell
                                            align="center"
                                            colSpan={colGroup.length}
                                            style={{ fontSize: '1rem' }}
                                        >
                                            {!colGroup.title.includes("Empty") && colGroup.title}
                                        </FormTableCell>
                                    )}
                                </FormRowGroupTableRow>
                                <FormTableRow>
                                    <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} ></FormTableCell>
                                    {(sector.columns || {}).map((column) => (
                                        <FormTableCell sx={{ width: CELL_WIDTH[column.cssClass] }} >
                                            {column.title}
                                        </FormTableCell>
                                    ))}
                                </FormTableRow>
                            </TableHead> */}
                            <TableBody>
                                {sector.rows.map((row) => (
                                    <FormTableRow>
                                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} >
                                            {row.title}
                                        </FormTableCell>
                                        {row.cells.map((cell) => (
                                            <FormTableCell sx={{ width: CELL_WIDTH[cell.cssClass] ? CELL_WIDTH[cell.cssClass] : CELL_WIDTH.GeneralCell }} >
                                                {sectorDataInt && cell.visible && getCellContent({
                                                    rowId: row.id,
                                                    cell: cell,
                                                    rowData: sectorDataInt && sectorDataInt[row.id]
                                                })}
                                            </FormTableCell>
                                        ))}

                                    </FormTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Fragment>
                </TableContainer>
                : <Skeleton variant="rectangular" width={'100%'} height={150} />
            }
        </>
    )
};

const RenewableElectricity = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const reportId = useSelector(state => state.common.reportId);
    const metaData = useSelector(state => state.renewableElectricity.metaData);
    const initialWholeFarmData = useSelector(state => state.renewableElectricity.initialWholeFarmData);
    const currentWholeFarmData = useSelector(state => state.renewableElectricity.currentWholeFarmData);
    const initialFormData = useSelector(state => state.renewableElectricity.initialFormData);
    const { successMsg, errorMsg } = useSelector(state => state.common);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);
    const {reportDetails: { reportName }} = useSelector(state => state.farmReport);

    const [formData, setFormData] = useState({})
    const [modalOpen, setModalOpen] = useState(false);
    const [nextClicked, setNextClicked] = useState(false);
    const [wholeFarmDataInt, setWholeFarmDataInt] = useState(undefined);
    const [wholeFarmDataExt, setWholeFarmDataExt] = useState(undefined);
    const [colGroups, setColGroups] = useState([]);

    const sectors = metaData?.form?.sectors || [];
    const wholeFarm = metaData?.form?.wholeFarm || [];
    const columns = metaData?.form?.columns || [];

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getRenewableElectricityMetaData({ reportId }));
        dispatch(getRenewableElectricityFormData({ reportId }));
        dispatch(resetResponseState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(resetRenewableElectricityMetaData());
        }
    }, []);

    useEffect(() => {
        const tempData = { ...initialFormData };
        setFormData(tempData);
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormData]);

    useEffect(() => {
        setWholeFarmDataInt({ ...initialWholeFarmData });
        setWholeFarmDataExt({ ...initialWholeFarmData });
    }, [initialWholeFarmData]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            successMsg && toastr.success(successMsg);
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(getRenewableElectricityFormData({ reportId }));
            dispatch(resetResponseState());
            if (successMsg && nextClicked) {
                navigate('/data-entry/energy-waste/renewable-heat');
            }
            setNextClicked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        let groupedList = _(columns ? columns : [])
            .groupBy("group")
            .map((items, Group) => ({
                title: Group,
                length: items.length,
            }))
            .value();
        setColGroups(groupedList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    useEffect(() => {
        if (wholeFarmDataExt && !_.isEmpty(wholeFarmDataExt)) {
            dispatch(setRenewableElectricityWholeFarmData({
                wholeFarmData: wholeFarmDataExt
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wholeFarmDataExt]);

    const handleModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            dispatch(getRenewableElectricityMetaData({ reportId }));
            dispatch(getRenewableElectricityFormData({ reportId }));
        }
    }

    const isFormUpdated = () => {
        const renewableElectricityData = store.getState().renewableElectricity;
        const isUpdatedWholeFarmData = !isEqual(renewableElectricityData?.initialWholeFarmData, renewableElectricityData?.currentWholeFarmData);
        const isUpdatedEnterpriseData = !isEqual(renewableElectricityData?.initialFormData, renewableElectricityData?.currentFormData);
        if(isUpdatedWholeFarmData || isUpdatedEnterpriseData){
            return true;
        }
        return false;
    }

    const handleSaveClick = (e) => {
        if(!isFormUpdated()){
            return;
        }
        dispatch(setLoading());
        dispatch(saveRenewableElectricityData({ reportId }));
    }

    const handleNextClick = (e) => {
        setNextClicked(true);
        if (props.locked || !isFormUpdated())
            navigate('/data-entry/energy-waste/renewable-heat');
        else {
            dispatch(setLoading());
            dispatch(saveRenewableElectricityData({ reportId }));
        };
    }

    const handleAllocateRenewableElectricityClick = () => {
        if (currentWholeFarmData && reportId)
            dispatch(getAllocateRenewableElectricity({ reportId, currentWholeFarmData }));
    }

    const handleInputChange = useCallback((e, colId, rule) => {
        let value = removeCommas(e.target.value);
        if (e.target.inputMode === "numeric") {
            let numericRegex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
            if (numericRegex.test(value)) {
                if (rule.max < parseFloat(value || 0)) {
                    toastr.warning(`This input only accepts values between ${addCommas(rule.min
                    )} and ${addCommas(rule.max)}.  please enter the value again.`,
                        undefined,
                        toastrCustomOptionsLonger
                    );
                } else {
                    setWholeFarmDataInt(produce((draft) => {
                        draft[colId] = value;
                        let tempRowData = { ...draft };
                        delete tempRowData['ElectricityTotalProduced'];
                        delete tempRowData['ElectricityTotalUsed'];
                        let rowValues = Object.values(tempRowData);
                        const totalValue = rowValues.reduce((x, y) => parseFloat(x || 0) + parseFloat(y || 0))
                        draft['ElectricityTotalProduced'] = totalValue || '';
                    }));
                    setWholeFarmDataExt(produce((draft) => {
                        draft[colId] = parseFloat(value);
                        let tempRowData = { ...draft };
                        delete tempRowData['ElectricityTotalProduced'];
                        delete tempRowData['ElectricityTotalUsed'];
                        let rowValues = Object.values(tempRowData);
                        const totalValue = rowValues.reduce((x, y) => parseFloat(x || 0) + parseFloat(y || 0))
                        draft['ElectricityTotalProduced'] = parseFloat(totalValue);
                    }));
                }
            }
        } else {
            setWholeFarmDataInt(produce((draft) => {
                draft[colId] = value;
            }));
            setWholeFarmDataExt(produce((draft) => {
                draft[colId] = parseFloat(value);
            }));
        }
    }, []);

    return (
        <ScrollSync>
        <Grid container spacing={2} className={props.locked && 'deactivated'}>
            <Grid item xs={12} md={12} lg={12}>
                <FormHeaderPaper sx={{ p: 2, display: "flex", flexDirection: "column" }} divider={false}>
                    <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                    <SelectedReport reportName={reportName} />
                    <FormTitleTypography variant="h5" component="div">
                        Renewable Electricity
                    </FormTitleTypography>
                    <FormDescriptionTypography variant="body2" paragraph>
                        Enter quantities of electricity produced and used on-farm from various renewable sources.
                    </FormDescriptionTypography>
                </FormHeaderPaper>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
                    <List sx={{ mb: 2 }}>
                        <ListSubheader sx={{ bgcolor: "background.paper" }}>
                            <ScrollSyncPane>
                                <>
                                <DataEntryButtonStack
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                    handleModalResponse={handleModalResponse}
                                    handleSaveClick={handleSaveClick}
                                    handleNextClick={handleNextClick}
                                />
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <FormRowGroupTableRow>
                                                <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} ></FormTableCell>
                                                {colGroups && colGroups.map((colGroup) =>
                                                    <FormTableCell
                                                        align="center"
                                                        colSpan={colGroup.length}
                                                        style={{ fontSize: '1rem' }}
                                                    >
                                                        {!colGroup.title.includes("Empty") && colGroup.title}
                                                    </FormTableCell>
                                                )}
                                            </FormRowGroupTableRow>
                                            <FormTableRow>
                                                <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} ></FormTableCell>
                                                {Object.values(wholeFarm || {}).map((column) => (
                                                    <FormTableCell sx={{ width: CELL_WIDTH[column.cssClass] }} >
                                                        {column.title}
                                                    </FormTableCell>
                                                ))}
                                            </FormTableRow>
                                    </TableHead>
                                    </Table>
                                </TableContainer>
                                </>
                            </ScrollSyncPane>
                        </ListSubheader>
                        {(wholeFarmDataInt && wholeFarm) && <> <TableContainer component={Paper} sx={{ mb: 3 }}>
                            <SectorTitleTypography>Whole Farm</SectorTitleTypography>
                            <Table>
                                <TableBody>
                                    <FormTableRow>
                                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}>Whole Farm</FormTableCell>
                                        {Object.values(wholeFarm || {}).map((cell,index) => (
                                            <FormTableCell>
                                                <DataEntryTextField
                                                    size="small"
                                                    variant="outlined"
                                                    inputProps={{ inputMode: "numeric" }}
                                                    style={{ width: 125, textAlign: "center" }}
                                                    disabled={cell.mappingProperty === 'ElectricityTotalProduced'}
                                                    value={wholeFarmDataInt && addCommas(wholeFarmDataInt[cell.mappingProperty])}
                                                    onChange={(e) => handleInputChange(e, cell.mappingProperty, cell.validations)}
                                                    autoFocus={props.locked === false && index === 0}
                                                />
                                            </FormTableCell>
                                        ))}
                                    </FormTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
                                <Button variant="contained" align="left" sx={{ textTransform: 'none', mb: 2 }}
                                    onClick={() => handleAllocateRenewableElectricityClick()}> Allocate renewable electricity
                                </Button>
                            </Stack>
                        </>}
                        {formData && sectors.map((sector, index) => (
                            <><React.Fragment key={index}>
                                <DataEntryTable
                                    key={sector.id}
                                    sector={sector}
                                    initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                                    colGroups={colGroups}
                                />
                            </React.Fragment></>
                        ))}
                    </List>    
                </Paper>
            </Grid>
        </Grid >
        </ScrollSync>
    )
}

export default RenewableElectricity