import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const CommonModal = ({ isOpen = false, handleResponse, value = '' }) => {
    const style = {
        position: 'absolute', top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)', width: 400,
        bgcolor: 'background.paper', border: '2px solid #000',
        boxShadow: 24, p: 4,
    };

    const [open, setOpen] = useState(isOpen);

    const handleClose = () => {
        handleResponse(false);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Modal open={open} onClose={handleClose}
            aria-labelledby="common-modal-title" aria-describedby="common-modal-description">
            <Box sx={style}>
                <Typography id="common-modal-title" variant="body1" component="h2" sx={{ marginBottom: 2 }}>
                    {value}
                </Typography>
                <Stack spacing={2} sx={{ marginLeft: 'auto' }} direction="row">
                    <Button variant="contained" sx={{ textTransform: 'none' }}
                            onClick={() => handleResponse(true)}>
                        Ok
                    </Button>
                    <Button variant="outlined" sx={{ textTransform: 'none' }} 
                            onClick={() => handleResponse(false)}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}

CommonModal.propTypes = {
    isOpen: PropTypes.bool,
    handleResponse: PropTypes.func,
    value: PropTypes.string
};
export default CommonModal