import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import { Grid, Paper, List, ListSubheader } from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormTableRow, FormTableCell,
  SectorTitleTypography, DataEntryTextField, FormHeaderPaper,
  FormDescriptionTypography
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { getFirstVisibleCellDetailsInDataEntry, DataEntryChecker, ManureManagementDataChecker } from '../../../helpers/dataEntryHelper';
import {
  getManureManagementFormData, getManureManagementMetadata, resetManureManagementMetadata,
  saveManureManagementData, setManureManagementFormData,
  getTransferredManureFormData, // This is for datachecks
  resetResponseState,
  unsetLoading,
  setLoading
} from "../../../store/appAction";
import { isEqual, isEmpty } from "lodash";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
  Pasture: "8%",
  HillGround: "8%",
  PoultryWithOrWithoutLitter: "8%",
  LiquidSlurry: "8%",
  SolidStorage: "8%",
  PitStorage: "8%",
  DeepBedding: "8%",
  AnaerobicDigestion: "8%",
  CheckTotal: "8%",
  ManureExported: "8%",
};

const DataEntryTable = ({ sector, initialFormData, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;
  const CHECK_TOTAL_KEY = 'ManureCheckTotal';
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState({});
  const [sectorDataExt, setSectorDataExt] = useState({});

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (sectorDataExt && !isEmpty(sectorDataExt)) {
      dispatch(setManureManagementFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = e.target.value;
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value || 0)) {
        toastr.warning(
          `This input only accepts values between ${rule.min} and ${rule.max}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = useCallback((
    e, groupId, rowId, mappingProperty, rule
  ) => {
    const excludedColumnKeys = ['ManureCheckTotal', 'ManureExported'];
    let value = e.target.value;
    if (e.target.inputMode === "numeric") {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        if (rule.max < numericValue) {
          toastr.warning(
            `This input only accepts values between ${rule.min} and ${rule.max}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            if (!excludedColumnKeys.includes(mappingProperty)) {
              let currentColTotal = draft[groupId][rowId][CHECK_TOTAL_KEY];
              const selectedCellValue = draft[groupId][rowId][mappingProperty];
              let totalValue = currentColTotal - selectedCellValue + numericValue;
              draft[groupId][rowId][CHECK_TOTAL_KEY] = totalValue.toFixed(2);
            }
            draft[groupId][rowId][mappingProperty] = value;
          }));
          setSectorDataExt(produce((draft) => {
            if (!excludedColumnKeys.includes(mappingProperty)) {
              let currentColTotal = draft[groupId][rowId][CHECK_TOTAL_KEY];
              const selectedCellValue = draft[groupId][rowId][mappingProperty];
              let totalValue = currentColTotal - selectedCellValue + numericValue;
              draft[groupId][rowId][CHECK_TOTAL_KEY] = totalValue.toFixed(2);
            }
            draft[groupId][rowId][mappingProperty] = value;
          }));
        }
      }
    } else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
    }
  }, []);

  const getCellContent = ({ groupId, rowId, cell, rowData, firstVisibleCellDetails, locked }) => {
    if (cell.dataType === "TextField") {
      return (
        <DataEntryTextField
          size="small"
          variant="outlined"
          inputProps={{ inputMode: "numeric" }}
          style={{
            width: 75,
            textAlign: "center",
            caretColor:
              cell.mappingProperty === CHECK_TOTAL_KEY ? "transparent" : "",
          }}
          onChange={(e) => {
            cell.mappingProperty !== CHECK_TOTAL_KEY &&
              handleInputChange(e, groupId, rowId, cell.mappingProperty, cell.validations);
          }}
          onBlur={(e) => handleOnBlur(e, cell.validations)}
          value={rowData?rowData[cell.mappingProperty]:''}
          error={
            cell.mappingProperty === CHECK_TOTAL_KEY &&
            rowData && rowData[CHECK_TOTAL_KEY] > cell.validations.max
          }
          autoFocus={locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId}
        />
      );
    } else {
      return <FormTableCell sx={{ width: "8%" }}></FormTableCell>;
    }
  };

return (
    <TableContainer component={Paper} sx={{ mb: 3 }}>
      {(sector.sectorGroups.groups || []).map((group) => (
        <Fragment key={group.id}>
          <SectorTitleTypography sx={{ mb: 2 }}>
            {sector.title} - {group.title}
          </SectorTitleTypography>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
            <TableHead>
              <FormTableRow>
                <FormTableCell></FormTableCell>
                {sector.columns.map((column) => (
                  <FormTableCell key={column.id} sx={{ width: '8%' }}>
                    {column.title}
                  </FormTableCell>
                ))}
              </FormTableRow>
            </TableHead>
            <TableBody>
              {group.rows.map((row) => (
                <FormTableRow key={row.id}>
                  <FormTableCell sx={{ width: '10%' }}>{row.title}</FormTableCell>
                  {row.cells.map((cell) => (
                    <FormTableCell key={cell.id}>
                      {sectorDataInt &&
                        cell.visible &&
                        getCellContent({
                          groupId: group.id,
                          rowId: row.id,
                          cell: cell,
                          rowData: sectorDataInt[group.id]?sectorDataInt[group.id][row.id]:null,
                          firstVisibleCellDetails: firstVisibleCellDetails,
                          locked: locked
                        })}
                    </FormTableCell>
                  ))}
                </FormTableRow>
              ))}
            </TableBody>
          </Table>
        </Fragment>
      ))}
    </TableContainer>
);
};

/***********************************************************************************************/
/***********************************************************************************************/
/***********************************************************************************************/
const ManureManagement = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.manureManagement.metaData);
  const initialFormData = useSelector(state => state.manureManagement.initialFormData);
  const trnsfManureFormData = useSelector(state => state.transferredManure.initialFormData);

  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const { reportDetails: { reportName }, reportConfig: { sectorConfigurations } } = useSelector(state => state.farmReport);

  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
   window.scrollY = 0;
    dispatch(setLoading());
    dispatch(getManureManagementMetadata({ reportId }));
    dispatch(getManureManagementFormData({ reportId }));
    // Transferred Manure data from L&C is used in the data checks
    dispatch(getTransferredManureFormData({ reportId }));

    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetManureManagementMetadata());
    }
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(getManureManagementFormData({ reportId }));
      // Transferred Manure data from L&C is used in the data checks
      dispatch(getTransferredManureFormData({ reportId }));
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/livestock/feed');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.scrollY = 0;
      dispatch(getManureManagementMetadata({ reportId }));
      dispatch(getManureManagementFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const manureManagementData = store.getState().manureManagement;
    const isUpdated = !isEqual(manureManagementData?.initialFormData, manureManagementData?.currentFormData);
    return isUpdated;
  }

  const validateInputs = () => {
    let isValid={status:true, message:''};  
    
    const manureManagementData = store.getState().manureManagement?.currentFormData;
    
    if(!sectorConfigurations || !sectors || !manureManagementData){
      console.log(`Problem with one or more of sectorConfigurations, sectors or manureManagementData`);
      // At this point we don't prevent the user from continuing if there is a data problem
      // This will be revised in the future.
      return isValid;
    }
    let mmCheck = new ManureManagementDataChecker(sectorConfigurations, sectors, manureManagementData,trnsfManureFormData);
    console.log(mmCheck.toString())

    let mmMismatches = mmCheck.checkManureTransfers();
    if(mmMismatches.length)
    {
      isValid.status=false;
      isValid.message = `<b>Mismatch between Manure Management and Transferred Manure</b><br>
      The following item${mmMismatches.length>1?'s are':' is'} not exporting all of their manure,
      but there is no data for that sector in Transferred Manures:<br> `
      + `${mmMismatches.join(', ')}`
      +`<br>Please check ${mmMismatches.length>1?'these':'this'} entr${mmMismatches.length>1?'ies':'y'}.`
    }
    return isValid;
  }

  const saveActions = () => {
    dispatch(setLoading());
    dispatch(saveManureManagementData({ reportId }));
  }
  const handleSaveClick = DataEntryChecker.generateSaveHandler(isFormUpdated, validateInputs, saveActions,'Warning');

  const nextActions = () => {
    setNextClicked(true);
    navigate('/data-entry/livestock/feed');
  }
  const handleNextClick = DataEntryChecker.generateNextHandler(isFormUpdated, validateInputs, saveActions, 
    props.locked, nextActions, setNextClicked, 'Warning');
  
  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper sx={{ p: 2, display: "flex", flexDirection: "column"}} divider={false}>
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName} />
            <SelectedReport reportName={reportName} />
            <FormTitleTypography variant="h5" component="div">
              Manure Management
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              Specify how home-produced livestock manure was managed.  Percentages should be entered based on the fraction of the reporting period each management strategy was applied.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop: '0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                    <DataEntryButtonStack modalOpen={modalOpen} setModalOpen={setModalOpen}
                      handleModalResponse={handleModalResponse} handleSaveClick={handleSaveClick}
                      handleNextClick={handleNextClick}/>
                </ScrollSyncPane>
              </ListSubheader>
              {firstVisibleCellDetails && sectors.map((sector, index) => (
                    <DataEntryTable
                      key={sector.id}
                      sector={sector}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}/>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
};

export default ManureManagement;
