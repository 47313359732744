import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import {
  Grid, Paper, List, FormControl, MenuItem
} from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormRowGroupTableRow, FormTableRow,
  FormTableCell, SectorTableTitleTypography, FormHeaderPaper,
  DataEntryTextField, FormDescriptionTypography, DataEntrySelect,
  NoMaxWidthTooltip,CustomWidthTooltip
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ListSubheader from "@mui/material/ListSubheader";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from "lodash";
import { isEqual } from "lodash";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { addCommas, removeCommas } from "../../../helpers/stringFormatHelper";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import {
  resetResponseState, unsetLoading, setLoading,
  getSoilPropertiesMetadata, resetSoilPropertiesMetadata, getSoilPropertiesFormData, saveSoilPropertiesData, setSoilPropertiesFormData
} from "../../../store/appAction";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';
import EmptyTableBody from '../../../components/Tables/EmptyTableBody';

const CELL_WIDTH = {
  RowTitle: "16%",
  NumberPurchases: "15%",
  AverageLiveWeight: "15%",
  NumberSales: "15%",
  AvgNumLivestock: "15%",
  KO: "15%",
  NumberDeaths: "15%",
};

const DataEntryTable = ({ key, sector, initialFormData, firstVisibleCellDetails, locked, index }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState({});
  const [sectorDataExt, setSectorDataExt] = useState({});


  const GRAZING_CUTTING_INTENSITY_ITEMS = [{ id: '', name: 'Please select' }, { id: 1, name: 'Very Extensive' }, { id: 2, name: 'Extensive' }, { id: 3, name: 'Moderate' }, { id: 3, name: 'Intensive' }, { id: 3, name: 'Very Intensive' }];
  const LIMING_ITEMS = [{ id: '', name: 'Please select' }, { id: 1, name: 'No Limiting' }, { id: 2, name: 'Limiting' }];


  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setSoilPropertiesFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = removeCommas(e.target.value);
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value)) {
        toastr.warning(
          `This input only accepts values between ${addCommas(
            rule.min
          )} and ${addCommas(rule.max)}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = useCallback((
    e, groupId, rowId, cell
  ) => {
    let value = removeCommas(e.target.value);
    if (cell.dataType === 'TextField') {
      if (e.target.inputMode === "numeric") {
        let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (numericRegex.test(value)) {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = value;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = parseFloat(value);
          }));
        }
      }
    }

    if (cell.dataType === 'Select') {
      const value = e.target.value;

      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value === '' ? null : value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value === '' ? null : value;
      }));


    }


  }, []);

  const getCellContent = ({ groupId, rowId, cell, rowData, firstVisibleCellDetails, locked }) => {

    const getDropdownMenuItems = (mappingProperty) => {

      if (mappingProperty === 'GCuttingIntensityBefore') {
        const data = GRAZING_CUTTING_INTENSITY_ITEMS.map((option) => {
          return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
        })
        return data;
      }

      if (mappingProperty === 'LimingBefore') {
        const data = LIMING_ITEMS.map((option) => {
          return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
        })
        return data;
      }
    }
    switch (cell.dataType) {
      case "TextField":
        return (
          <DataEntryTextField
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width: 100, textAlign: "center" }}
            value={(rowData && rowData[cell.mappingProperty]) || ''}
            onChange={(e) =>
              handleInputChange(e, groupId, rowId, cell)
            }
            onBlur={(e) => handleOnBlur(e, cell.validations)
            }
            disabled={cell?.readOnly}
          />
        );

      case 'Select':
        return (
          <FormControl sx={{ width: '100%', margin: 0 }}>
            <DataEntrySelect
              value={rowData && rowData[cell.mappingProperty] !== null ?
                rowData[cell.mappingProperty] : ''}
              displayEmpty
              onChange={(e) => handleInputChange(e, groupId, rowId, cell)}
            >

              {getDropdownMenuItems(cell.mappingProperty)}

            </DataEntrySelect>
          </FormControl >
        );
      default:
        return <FormTableCell></FormTableCell>;
    }
  };

  return (
    <>
      {
        (sector.sectorGroups.groups || []).map((group) => (
          <>
            <FormTableRow > <FormTableCell colSpan={5}> <SectorTableTitleTypography style={{ margin: '0 !important', padding: '0 !important' }} variant='body1'>{sector.title} - {group.title}</SectorTableTitleTypography>  </FormTableCell>  </FormTableRow>
            {
              group.rows.map((row) => (

                <FormTableRow>
                  <FormTableCell >{row.title}</FormTableCell>
                  {row.cells.map((cell) => (
                    <FormTableCell sx={{ minWidth: cell.dataType === 'Select' ? '150px' : '' }}>
                      {sectorDataInt && cell.visible &&
                        getCellContent({
                          groupId: group.id,
                          rowId: row.id,
                          cell: cell,
                          rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                          isOtherSales: false,
                          firstVisibleCellDetails: firstVisibleCellDetails,
                          locked: locked
                        })}
                    </FormTableCell>
                  ))}
                </FormTableRow>
              ))
            }
          </>
        ))
      }
    </>
  );
};


const SoilProperties = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector((state) => state.soilProperties.metaData);
  const initialFormData = useSelector((state) => state.soilProperties.initialFormData);

  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const {reportDetails: { reportName }} = useSelector(state => state.farmReport);

  const [formData, setFormData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
   window.scrollY = 0;
    dispatch(setLoading());
    dispatch(getSoilPropertiesMetadata({ reportId }));
    dispatch(getSoilPropertiesFormData({ reportId }));
    dispatch(resetResponseState());

    return () => {
      dispatch(resetSoilPropertiesMetadata());
    }
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 4000);
  }, [initialFormData]);


  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/energy-waste/electricity-fuel');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const isFormUpdated = () => {
    const soilPropertiesData = store.getState().soilProperties;
    const isUpdated = !isEqual(soilPropertiesData?.initialFormData, soilPropertiesData?.currentFormData);
    return isUpdated;
  }

  const handleSaveClick = (e) => {
    if (!isFormUpdated()) {
      return;
    }
    dispatch(setLoading());
    dispatch(saveSoilPropertiesData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      navigate('/data-entry/energy-waste/electricity-fuel');
    }
    else {
      dispatch(setLoading());
      dispatch(saveSoilPropertiesData({ reportId }));
    }
  }

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
     window.scrollY = 0;
      dispatch(getSoilPropertiesMetadata({ reportId }));
      dispatch(getSoilPropertiesFormData({ reportId }));
    }
  };

  const renderPeatTooltip = () => {
    const text = `
    <div>Peat and other organic soils behave differently to mineral soils, and are more vulnerable to degradation.
    Currently Agrecalc treats all soils as mineral soils.
    Updated results that capture emissions from peat will be available with Agrecalc's Peatland update.</div>
    `;
    return <div dangerouslySetInnerHTML={{ __html: text }} style={{ padding: '5px' }} />
  };

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper sx={{ p: 2, display: "flex", flexDirection: "column",}}
            divider={false} >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName} />
            <SelectedReport reportName={reportName} />
            <FormTitleTypography variant="h5" component="div">
              {"Soil Properties"}
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              If you have done soil testing and know your carbon stocks then this can be used to refine our estimate of your soil carbon change
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop: '0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSync>
                    <DataEntryButtonStack
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      handleModalResponse={handleModalResponse}
                      handleSaveClick={handleSaveClick}
                      handleNextClick={handleNextClick}
                    />
                </ScrollSync>
              </ListSubheader>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table
                  size="large"
                  aria-label="customized table"
                >
                  <TableHead>
                    <FormTableRow >
                      <FormTableCell rowSpan={3}></FormTableCell>
                      <FormTableCell rowSpan={2} colSpan={3} > Soil Test </FormTableCell>
                      <FormTableCell rowSpan={3} colSpan={2} align="center"> {"% of Crop on Peaty Soil"}
                          <CustomWidthTooltip title={renderPeatTooltip()} placement="bottom-start">
                            <InfoOutlinedIcon  color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' />
                          </CustomWidthTooltip>
                      </FormTableCell>
                    </FormTableRow>

                    <FormTableRow>

                    </FormTableRow>

                    <FormTableRow>
                      <FormTableCell > Year </FormTableCell>
                      <NoMaxWidthTooltip>
                        <FormTableCell > Depth (cm) </FormTableCell>
                      </NoMaxWidthTooltip>
                      <FormTableCell > Carbon Stocks (tC\ha) </FormTableCell>

                    </FormTableRow>
                  </TableHead>

                  <TableBody>
                    { _.isEmpty(formData) &&  <EmptyTableBody title="data"/>  }
                    {initialFormData && formData && firstVisibleCellDetails && sectors.map((sector, index) => (
                      <>
                        <React.Fragment key={index}>
                          <DataEntryTable
                            key={sector.id}
                            sector={sector}
                            initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                            firstVisibleCellDetails={firstVisibleCellDetails}
                            locked={props.locked}
                            index={index}
                          />
                        </React.Fragment>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
};

export default SoilProperties;
