export const ResultStatus = Object.freeze({
    NotCalculated: 0,
    Completed: 1,
    Error: 2
});

export const ReportStatus = Object.freeze({
    ReportInitiated: 0,
    DataChecksErrors: 1,
    DataChecksPassed: 2,
    CalculationInProgress: 3,
    CalculationCompleted: 4,
    ReviewRequested: 5,
    ReviewInProgress: 6,
    ReviewCompletedAndValid: 7,
    ReviewCompletedAndInvalid: 8,
    CalculationErrorOccured: 9,
    isCalculated: (status) => { return (status === ReportStatus.CalculationCompleted) },
    isPreCalculation: (status) => { return (status < ReportStatus.CalculationCompleted) },
    isUnderReview: (status) => { return ([ReportStatus.ReviewRequested, ReportStatus.ReviewInProgress].includes(status)) },
    isValidated: (status) => { return ([ReportStatus.ReviewCompletedAndValid, ReportStatus.ReviewCompletedAndInvalid].includes(status)) },
    canSubmit: (status) => { return ([ReportStatus.CalculationCompleted, ReportStatus.DataChecksErrors].includes(status)) }
});


export const showNonValidatedReportText = (status) => {
    return (status !== ReportStatus.ReviewCompletedAndValid
        && status !== ReportStatus.ReviewCompletedAndInvalid);
}

export const ReportHlp = Object.freeze({
    getYearFromStr: (str) => { return (new Date(str)).getUTCFullYear(); },

    findSameYearAlreadyValidated: (reportList, reportID) => {
        let rept = reportList?.find((r) => (r.reportId === reportID));
        if (rept) {
            let reptYearEnd = ReportHlp.getYearFromStr(rept.yearEnd);
            let match = reportList?.find((r) => (
                ReportHlp.getYearFromStr(r.yearEnd) === reptYearEnd
                && r.reportId !== rept.reportID
                && ReportStatus.isValidated(r.status)));
            return match;
        }
        return null;
    }
});

// hack to exclude Hedges from results until it's corrected in the backend
export const resultsDataMap = (rowData, noFooter, mapFn) => {
    const titleMatch = (row)=> {return row.itemTitle?.match(/Hedges \([<>]30yrs\)/)==null};
    if(rowData){
        let entries = Object.entries(rowData.filter(titleMatch))
        return entries.slice(0, entries.length - (noFooter ? 0 : 1)).map(mapFn)
    }
}