import { FormControl, Grid, List, ListSubheader, MenuItem, Paper, Skeleton,
  Table, TableBody,TableContainer, TableHead, TableRow} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import _ from 'lodash';
import produce from 'immer';
import { FormHeaderPaper, FormTitleTypography, FormTableCell, FormTableRow, SectorTitleTypography,TableListItem,
  DataEntryTextField, DataEntrySelect, FormDescriptionTypography } from '../../../components/CustomStyles/StyledComponents';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import {
  getLandAreaCropsColumnTotal, getLandAreaCropsFormData,
  getLandAreaCropsMetadata, resetLandAreaCropsMetaData, getLandAreaCropsOptions, resetResponseState,
  saveLandAreaCropsData, setLandAreaCropsColumnTotal, setLandAreaCropsFormData, setLoading, unsetLoading,getFarmReport, setLandAreaCropsMiscData, getGrazingSystemOptions
} from '../../../store/appAction';
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry, LandAndCropsParams,
  LandAndCropsDataChecker, CellWidthTracker, ParamValidator, 
  DataEntryChecker } from '../../../helpers/dataEntryHelper';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

/***********************************************************************************************/
/***********************************************************************************************/
/***********************************************************************************************/

const DataEntryTable = ({ sector, initialFormData, landUseOptions, grazingSystemOptions, includeSoilCarbon, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setLandAreaCropsFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = e.target.value;
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value)) {
        toastr.warning(
          `This input only accepts values between ${rule.min} and ${rule.max}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = async (e, groupId, rowId, mappingProperty, rule) => {
    const excludedSectors = ['Hedges', 'Total area (ha)']; //temporarily check by title

    let value = e.target.value;
    const colTotal = await getLandAreaCropsColumnTotal();
    let tempColData = { ...colTotal };

    if (e.target.inputMode === 'numeric') {
      value = removeCommas(value);
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        const max = /*rule.maxNoYear ? parseFloat(noYear || 0) :*/ rule?.max || 100; //noYear value may retrieve separately 
        if (max < numericValue) {
          toastr.warning(
            `This input only accepts values between ${rule.min} and ${addCommas(max)}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        }
        else {
          if (!excludedSectors.includes(sector.title) &&
            (LandAndCropsParams.isOwnedOrSeasonalParam(mappingProperty))) {
            let currentColTotal =
              parseFloat(tempColData[mappingProperty.concat('Total')] || 0) - parseFloat(sectorDataInt[groupId][rowId][mappingProperty] || 0);
            let areaTotal = currentColTotal + numericValue;
            tempColData[mappingProperty.concat('Total')] = parseFloat(areaTotal);
            dispatch(setLandAreaCropsColumnTotal(tempColData));
          }
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = value;
            draft[groupId][rowId].TotalLandArea =
              parseFloat(draft[groupId][rowId].OwnedAndTenantedLand|| 0) +
              parseFloat(draft[groupId][rowId].SeasonalLand || 0);
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = parseFloat(value);
            draft[groupId][rowId].TotalLandArea =
              parseFloat(draft[groupId][rowId].OwnedAndTenantedLand || 0) +
              parseFloat(draft[groupId][rowId].SeasonalLand || 0);
          }));
        }
      }
    }
    else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value || null;
      }));
    }
  };

  const fetchRowTitle = (title, landAreaTotal) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    return title.concat(
      ' (', addCommas(landAreaTotal?.TotalLandArea || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }

  const getCellContent = ({ groupId, rowId, cell, rowData, firstVisibleCellDetails, locked }) => {
    switch (cell.dataType) {
      case 'TextField': // TODO replace addCommas with https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
        return ( <DataEntryTextField key={rowId + cell.mappingProperty}
            size="small" variant="outlined" inputProps={{ inputMode: 'numeric' }}
            style={{ width: 75 }} value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            onChange={(e) => handleInputChange( e, groupId, rowId, cell.mappingProperty, cell.validations)}
            onBlur={(e) => handleOnBlur(e, cell.validations)}
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
            error= {locked && cell && rowData?(!ParamValidator.isValidValue(cell.mappingProperty,rowData[cell.mappingProperty],sector.title)):false}
          />
        );
      case 'Select':
        return ( <FormControl key={rowId + cell.mappingProperty}
            variant="outlined" sx={{ width: '98%', margin: 0 }} >
            <DataEntrySelect displayEmpty
              value={rowData && rowData[cell.mappingProperty] !== null ? rowData[cell.mappingProperty] : ''}
              onChange={(e) => handleInputChange( e, groupId, rowId, cell.mappingProperty, cell.validations)}
              autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }>
              <MenuItem value=""><em>Please Select</em></MenuItem>
              {cell.mappingProperty === 'LandUseBeforeConvertion' && landUseOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
              {cell.mappingProperty === 'GrazingSystem' && grazingSystemOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
            </DataEntrySelect>
          </FormControl>
        );
      default:
        return <></>;
    }
  }

  return (
    sector ?
      <ScrollSyncPane>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
            <TableBody>
              {(sector.sectorGroups.groups || []).map((group) => (
                group.rows.map((row) => (
                  <FormTableRow key={row.id}>
                    <FormTableCell sx={{ width: CellWidthTracker.getCellWPct('RowTitle') }} >
                      {sectorDataInt &&
                        fetchRowTitle(row.title, sectorDataInt[group.id] && sectorDataInt[group.id][row.id])}
                    </FormTableCell>
                    {row.cells.map((cell) => (
                      includeSoilCarbon ?
                        <FormTableCell key={row.id + cell.id} sx={{ width: CellWidthTracker.getCellWPct(cell.mappingProperty) }}>
                          {cell.visible && sectorDataInt &&
                            getCellContent({
                              groupId: group.id, rowId: row.id,
                              cell: cell, rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                              firstVisibleCellDetails: firstVisibleCellDetails, locked: locked
                            })}
                        </FormTableCell> :
                        !LandAndCropsParams.isSoilCarbonParam(cell.mappingProperty) &&
                        <FormTableCell sx={{ width: CellWidthTracker.getCellWPct(cell.mappingProperty) }}>
                          {cell.visible && sectorDataInt &&
                            getCellContent({
                              groupId: group.id, rowId: row.id, cell: cell,
                              rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                              firstVisibleCellDetails: firstVisibleCellDetails, locked: locked
                            })}
                        </FormTableCell>
                    ))}
                  </FormTableRow>
                ))
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollSyncPane>
      : <Skeleton variant="rectangular" width={'100%'} height={150} />
  );
}

/***********************************************************************************************/
/***********************************************************************************************/
/***********************************************************************************************/
const TotalTable = ({ sector, includeSoilCarbon }) => {
  toastr.options = toastrCustomOptions;
  const columnTotal = useSelector(state => state.landAreaCrops.columnTotal);
  const miscData = useSelector(state => state.landAreaCrops.currentReportData);
  const currentReportData = useSelector(state => state.landAreaCrops.currentReportData);
  const [totalTableData, setTotalTableData] = useState([]);



  useEffect(() => {
    let tempColData = { ...columnTotal };
    const areaTotalOfOwnedTenantedLand = parseFloat(tempColData['OwnedAndTenantedLandTotal'] || 0 )  + parseFloat(miscData['OwnedTenantedLandRYandB'] || 0 );
    tempColData['OwnedAndTenantedLandTotal'] = parseFloat(areaTotalOfOwnedTenantedLand);

    const areaTotalOfSeasonalLand = parseFloat(tempColData['SeasonalLandTotal'] || 0 )  + parseFloat(miscData['SeasonalLandRYandB'] || 0 );
    tempColData['SeasonalLandTotal'] = parseFloat(areaTotalOfSeasonalLand);
    setTotalTableData(tempColData);


  }, [columnTotal,miscData,currentReportData ]);
  

  const fetchRowTitle = (title) => {
    let total = parseFloat(totalTableData.OwnedAndTenantedLandTotal || 0) + parseFloat(totalTableData.SeasonalLandTotal || 0)
    return title.concat(' (', total.toFixed(2), ' ha)');
  }

  const getCellContent = (cell) => {
    if (cell.dataType === 'TextField') {
      return (
        <DataEntryTextField key={cell.mappingProperty}
          size="small"  variant="outlined"
          inputProps={{ inputMode: 'numeric' }}
          style={{ width: 75 }} disabled={true}
          value={addCommas((totalTableData[cell.mappingProperty] || 0).toFixed(2))}
        />
      );
    }
    return <></>;
  }

  return (totalTableData ?
    <ScrollSyncPane>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
          <TableBody>
            {(sector.sectorGroups.groups || []).map((group) => (
              group.rows.map((row) => {
                let cwt= new CellWidthTracker(includeSoilCarbon,CellWidthTracker.Total);
                return (<FormTableRow key={row.id}>
                  <FormTableCell sx={{ width: cwt.popCellWPct('RowTitle') }} >
                    {fetchRowTitle(row.title)}
                  </FormTableCell>
                  {row.cells.map((cell) => (
                    includeSoilCarbon ?
                      <FormTableCell key={cell.id} sx={{ width: cwt.popCellWPct(cell.mappingProperty) }}>
                        {cell.visible && totalTableData && getCellContent(cell)}
                      </FormTableCell> :
                      !LandAndCropsParams.isSoilCarbonParam(cell.mappingProperty) &&
                      <FormTableCell sx={{ width:cwt.popCellWPct(cell.mappingProperty) }}>
                        {cell.visible && totalTableData &&
                          getCellContent(cell)}
                      </FormTableCell>
                  ))}
                   {cwt.getRemainder().map((cellW) =>
                    <FormTableCell key={cellW.key} sx={{ width: cellW.width }}></FormTableCell> 
                  )}
                </FormTableRow>)
                })
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ScrollSyncPane>
    : <Skeleton variant="rectangular" width={'100%'} height={150} />
  );
};


/***********************************************************************************************/
/***********************************************************************************************/
/***********************************************************************************************/
const MiscTable = ({ initialMiscData, metaData, includeSoilCarbon }) => {
  const dispatch = useDispatch();
  const [miscDataInt, setMiscDataInt] = useState(undefined);
  const [miscDataExt, setMiscDataExt] = useState(undefined);

  useEffect(() => {
    setMiscDataInt(initialMiscData);
    setMiscDataExt(initialMiscData);
  }, [initialMiscData]);

  useEffect(() => {
    if (miscDataExt && !_.isEmpty(miscDataExt)) {
      dispatch(setLandAreaCropsMiscData(miscDataExt));
    }
  }, [miscDataExt]);

  const handleMiscInputChange = async (e, mappingProperty, rule) => {
    let value = e.target.value;
    if (e.target.inputMode === 'numeric') {
      
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        const colTotal = await getLandAreaCropsColumnTotal();
        let tempColData = { ...colTotal };
        value = removeCommas(value);
        let numericValue = parseFloat(value || 0);

        if(mappingProperty === 'OwnedTenantedLandRYandB'){
          let currentColTotal = parseFloat(tempColData['OwnedAndTenantedLandTotal'] || 0 )  - parseFloat(miscDataInt['OwnedTenantedLandRYandB']|| 0);
          let areaTotal = currentColTotal + numericValue;
          tempColData['OwnedAndTenantedLandTotal'] = parseFloat(areaTotal);
        }
        if(mappingProperty === 'SeasonalLandRYandB'){
          const currentColTotal = parseFloat(tempColData['SeasonalLandTotal'] || 0 )  - parseFloat(miscDataInt['SeasonalLandRYandB']|| 0);
          let areaTotal = currentColTotal + numericValue;
          tempColData['SeasonalLandTotal'] = parseFloat(areaTotal);
        }
        
        setMiscDataInt(produce((draft) => {
          draft[mappingProperty]= value;
        }));
        setMiscDataExt(produce((draft) => {
          draft[mappingProperty] = value;
        }));
      }
    }
  };

  const getCellContent = (cell) => {
    if (cell.dataType === 'TextField') {
      return (
        <DataEntryTextField key={cell.mappingProperty}
          size="small" variant="outlined"  inputProps={{ inputMode: 'numeric' }}
          style={{ width: 75 }} disabled={cell?.readOnly}
          value={miscDataInt[cell.mappingProperty] || ''}
          onChange={(e) => handleMiscInputChange(e, cell.mappingProperty, cell.validations)}
        />
      );
    }
    return <></>;
  }


  const fetchRowTitle = (title) => {
    let total = parseFloat(miscDataInt['OwnedTenantedLandRYandB'] || 0) + parseFloat(miscDataInt['SeasonalLandRYandB'] || 0)
    return title.concat(' (', total.toFixed(2) || 0, ' ha)');
  }

  return (miscDataInt && metaData?.sections?.map((section) => {
    return (
      <TableContainer key={section.id} component={Paper}>
        <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
          <TableHead >
            <FormTableRow>
              <FormTableCell colSpan={9} sx={{ textAlign: 'left' }} >
                {section.title}
              </FormTableCell>
            </FormTableRow>
          </TableHead>
          <TableBody>
            {section?.rows?.map((row) => {
              let cwt= new CellWidthTracker(includeSoilCarbon,CellWidthTracker.Misc);
              return (
                <FormTableRow key={row.id}>
                  <FormTableCell sx={{ textAlign: 'left', width: cwt.popCellWPct('RowTitle') }} >
                    {fetchRowTitle('Other land - roads, yards, buildings ')}
                  </FormTableCell>
                  {row?.cells?.map((cell) => {
                    return (includeSoilCarbon ?
                      <FormTableCell key={cell.id} sx={{ width: cwt.popCellWPct(cell.mappingProperty) }}>
                        {cell.visible && getCellContent(cell)}
                      </FormTableCell>
                      :
                      !LandAndCropsParams.isSoilCarbonParam(cell.mappingProperty) &&
                      <FormTableCell sx={{ width: cwt.popCellWPct(cell.mappingProperty) }}>
                        {cell.visible && getCellContent(cell)}
                      </FormTableCell>
                    )
                  })}
                  {cwt.getRemainder().map((cellW) =>
                    <FormTableCell key={cellW.key} sx={{ width: cellW.width }}></FormTableCell> 
                  )}
                  
                </FormTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  })
  );
}

/***********************************************************************************************/
/***********************************************************************************************/
/***********************************************************************************************/
const LandCrops = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.landAreaCrops.metaData);
  const landUseOptions = useSelector(state => state.landAreaCrops.landUseOptions);
  const grazingSystemOptions = useSelector(state => state.soilCarbon.grazingSystemOptions);
  const initialFormData = useSelector(state => state.landAreaCrops.initialFormData);
  const initialReportData = useSelector(state => state.landAreaCrops.initialReportData);
  const { reportDetails, reportConfig: { livestockOnly }, reportConfig: { sectorConfigurations } } = useSelector(state => state.farmReport);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);

  const [formData, setFormData] = useState({})
  const [miscData, setMiscData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];
  const columns = metaData?.form?.columns || [];
  const miscTableMeta =  metaData?.form?.reportData || [];
  
  const totalTableMeta = {
    "title": "Total area (ha)",
    "sectorGroups": {
      "sectorGroupSummaries": null,
      "groups": [
        {
          "title": "Total",
          "rows": [
            {
              "title": "Total",
              "cells": [
                {
                  "dataType": "TextField",
                  "visible": true,
                  "mappingProperty": "OwnedAndTenantedLandTotal",
                },
                {
                  "dataType": "TextField",
                  "visible": true,
                  "mappingProperty": "SeasonalLandTotal",
                }
              ]
            }
          ],
        }
      ]
    }
  }

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getFarmReport({ reportId }));
    dispatch(getLandAreaCropsOptions());
    dispatch(getGrazingSystemOptions());
    dispatch(getLandAreaCropsMetadata({ reportId }));
    dispatch(getLandAreaCropsFormData({ reportId }));
    dispatch(resetResponseState());
    //dispatch(resetLandAreaCropsColumnTotal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetLandAreaCropsMetaData());
    }
  }, []);

  useEffect(() => {
    // Report doesn't have Land and Crops data so reditrect to livestock
    if (livestockOnly) {
      dispatch(unsetLoading());
      navigate('/data-entry/livestock/numbers-weights')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livestockOnly]);

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      dispatch(unsetLoading());
      setFormData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (initialReportData && !_.isEmpty(initialReportData)) {
      const tempData = { ...initialReportData };
      dispatch(unsetLoading());
      setMiscData(tempData);
    }
  }, [initialReportData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(getLandAreaCropsFormData({ reportId }));
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/land-crops/fertiliser-lime');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.scrollY = 0;
      dispatch(getLandAreaCropsMetadata({ reportId }));
      dispatch(getLandAreaCropsFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const landAreaCropsData = store.getState().landAreaCrops;
    const isUpdatedFormData = !_.isEqual(landAreaCropsData?.initialFormData, landAreaCropsData?.currentFormData);
    const isUpdateMiscData =  !_.isEqual(landAreaCropsData?.initialReportData, landAreaCropsData?.currentReportData);

    return (isUpdatedFormData || isUpdateMiscData)
  }

  const validateInputs = () =>{
    let isValid={ status:true, message:''};  
    
    const landAreaCropsData = store.getState().landAreaCrops?.currentFormData;
    
    if(!sectorConfigurations || !sectors || !landAreaCropsData){
      console.log(`Problem with one or more of sectorConfigurations, sectors or landAreaCropsData`);
      return isValid;
    }

    let lacCheck = new LandAndCropsDataChecker(sectorConfigurations, sectors, landAreaCropsData)

    let noYinGround = lacCheck.checkNumberYearsCropInGround()
    if(noYinGround.length)
    {
      isValid.status=false;
      isValid.message = `The following item${noYinGround.length>1?'(s) are':' is'} missing 
      the number of years crop is in the ground:<br> `
      + `${noYinGround.join(', ')}`
    }
    return isValid;
  }

  const saveActions = () => {
    dispatch(setLoading());
    dispatch(saveLandAreaCropsData({ reportId }));
  }
  const handleSaveClick = DataEntryChecker.generateSaveHandler(isFormUpdated, validateInputs, saveActions);

  const nextActions = () => {
    setNextClicked(true);
    navigate('/data-entry/land-crops/fertiliser-lime');
  }
  const handleNextClick = DataEntryChecker.generateNextHandler(isFormUpdated, validateInputs, saveActions, 
                          props.locked, nextActions, setNextClicked);

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked?'deactivated':''}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper sx={{ p: 2, display: 'flex', flexDirection: 'column',}} divider={false}>
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <SelectedReport reportName={reportDetails?.reportName} />
            <FormTitleTypography variant="h5" component="div">
              {'Land Area & Crops'}
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              For all land uses on the farm, enter total area and land use history where relevant.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                  <>
                  <DataEntryButtonStack
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleModalResponse={handleModalResponse}
                    handleSaveClick={handleSaveClick}
                    handleNextClick={handleNextClick}
                  />
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <FormTableCell sx={{ width: CellWidthTracker.getCellWPct('RowTitle') }}></FormTableCell>
                          {columns.map((column) =>
                            <FormTableCell key={column.id} align="center" sx={{ width: CellWidthTracker.getCellWPct(column.cssClass) }}>
                              {column.title}
                            </FormTableCell>)
                          }
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  </>
                </ScrollSyncPane>
              </ListSubheader>
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <DataEntryTable
                      key={sector.id}
                      sector={sector}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      landUseOptions={landUseOptions}
                      grazingSystemOptions={grazingSystemOptions}
                      includeSoilCarbon={reportDetails?.includeSoilCarbon}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}
                    />
                  </TableListItem>
                </React.Fragment>
              ))}
              {miscData && 
                <React.Fragment key={-2}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{'Misc'} </SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <MiscTable
                      key={-12}
                      initialMiscData =  { miscData }
                      metaData = { miscTableMeta }
                      sector={totalTableMeta}
                      includeSoilCarbon={reportDetails?.includeSoilCarbon}
                    />
                  </TableListItem>
              </React.Fragment>
              }
              <React.Fragment key={-1}>
                <TableListItem>
                  <SectorTitleTypography variant='body1'>{totalTableMeta.title}</SectorTitleTypography>
                </TableListItem>
                <TableListItem>
                  <TotalTable
                    key={-11}
                    sector={totalTableMeta}
                    includeSoilCarbon={reportDetails?.includeSoilCarbon}
                  />
                </TableListItem>
              </React.Fragment>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
}

export default LandCrops;

