import {
    Grid, Paper, Table, TableBody, TableContainer,
    TableFooter, TableHead, TableRow, Typography
} from '@mui/material'
import React, { } from 'react'
import PropTypes from 'prop-types';
import {
    ChartTitleTypography, FormDescriptionTypographyPDF,
    FormTitleTypographyPDF, ResultsTableCellPDF, ResultsTableRow, FormHeaderPaper, FormTitleTypography
} from '../../../../components/CustomStyles/StyledComponents';
import { addCommas } from '../../../../helpers/stringFormatHelper';
import { WHOLE_FARM_DETAILED_TABLES } from '../../../../constants/shellTables';
import _ from 'lodash';
import { resultsDataMap } from '../../../../helpers/reportResultsHelper';
import { Cyrb53 } from "../../../../helpers/hashHelper";

const Tables = ({ results = {} }) => {
    const DataTable = ({ tableId, tableData, rowData }) => {

        const generateCarbonStockTableHead = (tableData, rowData) => {
            const columns = _.compact([
                rowData?.showSoilCarbon && 'Soil Carbon',
                rowData?.showBiochar && 'Biochar',
                rowData?.showEstimatedBiochar && 'Estimated Biochar Sequestration*',
                'Hedges',
                'Forestry'
            ]);
            return (
                columns.map((column, idx) => (
                    <ResultsTableCellPDF key={Cyrb53(`${tableData?.tableTitle}${column}`, idx)}>
                        {<div dangerouslySetInnerHTML={{ __html: column }} />}
                    </ResultsTableCellPDF>
                ))
            );
        }

        let isCarbonStockChanges = (tableData?.tableTitle === 'Carbon Stock Changes')

        return <>
            {tableData &&
                <TableContainer component={Paper} sx={{
                    marginTop: '80px',
                }}>
                    <ChartTitleTypography sx={{ mb: 0 }}>{tableData.tableTitle}
                        {tableData?.tableTitleTooltip && <sup>&#8225;</sup>}
                    </ChartTitleTypography>
                    <Table id={tableId}>
                        <TableHead>
                            <TableRow>
                                <ResultsTableCellPDF ></ResultsTableCellPDF>
                                {isCarbonStockChanges && generateCarbonStockTableHead(tableData, rowData)}
                                {!isCarbonStockChanges && tableData.columns.map((column, idx) => (
                                    <ResultsTableCellPDF key={Cyrb53(`${tableData?.tableTitle}${column}`, idx)}>
                                        <div dangerouslySetInnerHTML={{ __html: column }} />
                                    </ResultsTableCellPDF>
                                ))}
                            </TableRow>
                        </TableHead>
                        {!isCarbonStockChanges ?
                            <TableBody>
                                {resultsDataMap(rowData,tableData.noFooter,([rowId, row]) => (
                                        <ResultsTableRow>
                                            {Object.entries(row).map(([cellId, cell], idx) => (
                                                <ResultsTableCellPDF key={Cyrb53(`${tableData?.tableTitle}${cellId}${cell}`, idx)}>
                                                    {cell ? addCommas(cell) : '--'}
                                                </ResultsTableCellPDF>
                                            ))}
                                        </ResultsTableRow>
                                    ))}
                            </TableBody> :
                            <TableBody>
                                {resultsDataMap(rowData?.results,tableData.noFooter,([rowId, row]) => (
                                        <ResultsTableRow>
                                            {Object.entries(row).map(([cellId, cell]) => (
                                                <ResultsTableCellPDF>
                                                    {cell ? addCommas(cell) : '--'}
                                                </ResultsTableCellPDF>
                                            ))}
                                        </ResultsTableRow>
                                    ))}
                            </TableBody>}
                        {!tableData.noFooter &&
                            <TableFooter sx={{ mb: 1 }}>
                                {!isCarbonStockChanges && rowData && Object.entries(rowData)
                                    .slice(Object.entries(rowData).length - 1)
                                    .map(([rowId, row]) => (
                                        <ResultsTableRow>
                                            {Object.entries(row).map(([cellId, cell]) => (
                                                <ResultsTableCellPDF>
                                                    {cell ? addCommas(cell) : '--'}
                                                </ResultsTableCellPDF>
                                            ))}
                                        </ResultsTableRow>
                                    ))}
                                {isCarbonStockChanges && rowData && Object.entries(rowData.results)
                                    .slice(Object.entries(rowData.results).length - 1)
                                    .map(([rowId, row]) => (
                                        <ResultsTableRow>
                                            {Object.entries(row).map(([cellId, cell]) => (
                                                <ResultsTableCellPDF>
                                                    {cell ? addCommas(cell) : '--'}
                                                </ResultsTableCellPDF>
                                            ))}
                                        </ResultsTableRow>
                                    ))}
                            </TableFooter>}
                    </Table>
                </TableContainer>
            }
            {
                tableId === 'carbonSequestrationResults' && rowData?.showEstimatedBiochar &&
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="caption" paragraph>
                        *The carbon associated with the biochar applied has already been claimed.
                        That means it cannot be included in your carbon footprint.
                        The values shown in this column are to provide an indication of how much sequestration may be occurring, despite its exclusion from your carbon footprint.
                    </Typography>
                </Grid>
            }
            {
                tableData?.tableTitleTooltip &&
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="caption" paragraph>
                        {<div dangerouslySetInnerHTML={{ __html: `<sup>&#8225;</sup> ${tableData?.tableTitleTooltip}` }} />}
                    </Typography>
                </Grid>
            }
            {
                tableId === 'methaneResults' &&
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="caption" paragraph>
                        <sup>†</sup> <em>GWP</em> is an alternative way of reporting methane emissions. Methane breaks
                        down much faster than other greenhouse gasses but has a large upfront
                        warming impact. GWP* aims to capture this effect better than the standard
                        GWP100 metric. When measuring using GWP*, increased methane emissions
                        have a large impact, but steady or declining emissions have a much smaller
                        impact..
                    </Typography>
                </Grid>
            }
        </>
    }
    DataTable.propTypes = {
        tableData: PropTypes.object,
        rowData: PropTypes.any
    };

    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                pageBreakAfter: 'always',
                marginTop: '70px',
                borderRadius: 0,
            }}
                p={2}
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '90px',
                            borderRadius: 0,
                        }}
                    >
                        <FormTitleTypographyPDF fontSize={20} variant="h6" component="div">
                            Whole Farm Emissions
                        </FormTitleTypographyPDF>
                        <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={1}>
                            {`A summary of emissions from carbon dioxide, methane and nitrous oxide for the whole 
                            farm and per enterprise is presented below. Total emissions are also expressed per unit of output, 
                            per hectare and per livestock unit equivalent to allow comparisons to be made. Per unit of output 
                            is the most common way to express emissions associated with the production of food products.`}
                        </FormDescriptionTypographyPDF>
                        <FormDescriptionTypographyPDF variant="body2" fontSize={10} paragraph mb={0}>
                            {`All emissions are expressed as kg CO₂-eq, unless otherwise indicated.`}
                        </FormDescriptionTypographyPDF>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction='column'>
                        {Object.entries(WHOLE_FARM_DETAILED_TABLES).map(([tableKey, tableData]) => (
                            <Grid item xs={12} sx={{
                                pageBreakInside: 'avoid'
                            }}>
                                <DataTable
                                    tableId={tableKey}
                                    tableData={tableData}
                                    rowData={results[tableKey]} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Tables