import { Button, Grid, Paper, Skeleton, Stack, Table, TableBody, TableContainer, TableHead, List, ListSubheader } from '@mui/material';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormHeaderPaper, FormTableCell, FormTableRow, FormTitleTypography, DataEntryTextField, SectorTitleTypography, FormDescriptionTypography } from '../../../components/CustomStyles/StyledComponents';
import { getAllocateElectricityFuel, getElectricityFuelFormData, getElectricityFuelMetaData, resetElectricityFuelMetaData, resetResponseState, saveElectricityFuelData, setElectricityFuelFormData, setLoading, setWholeFarmData, unsetLoading } from '../../../store/appAction';
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import _ from "lodash";
import { isEqual } from "lodash";
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInEnergyAndWasteDataEntry } from '../../../helpers/dataEntryHelper';
import produce from 'immer';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
    RowTitle: "20%",
    Electricity: "16%",
    RedDieselContractorsUse: "16%",
    RedDieselGrainDrying: "16%",
    WhiteDiesel: "16%",
    Petrol: "16%",
    GeneralCell: "16%"
};

const DataEntryTable = ({ sector, initialFormData }) => {
    const dispatch = useDispatch();
    const [sectorDataInt, setSectorDataInt] = useState(undefined);
    const [sectorDataExt, setSectorDataExt] = useState(undefined);

    useEffect(() => {
        setSectorDataInt(initialFormData);
        setSectorDataExt(initialFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormData]);

    useEffect(() => {
        if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
            dispatch(setElectricityFuelFormData({
                sectorId: sector.id,
                formData: sectorDataExt
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectorDataExt]);

    const handleInputChange = useCallback((e, rowId, colId, rule) => {
        let value = removeCommas(e.target.value);
        if (e.target.inputMode === "numeric") {
            let numericRegex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
            if (numericRegex.test(value)) {
                if (rule.max < parseFloat(value || 0)) {
                    toastr.warning(`This input only accepts values between ${addCommas(rule.min
                    )} and ${addCommas(rule.max)}.  Please enter the value again.`,
                        undefined,
                        toastrCustomOptionsLonger
                    );
                } else {
                    setSectorDataInt(produce((draft) => {
                        draft[rowId][colId] = value;
                    }));
                    setSectorDataExt(produce((draft) => {
                        draft[rowId][colId] = parseFloat(value);
                    }));
                }
            }
        } else {
            setSectorDataInt(produce((draft) => {
                draft[rowId][colId] = value;
            }));
            setSectorDataExt(produce((draft) => {
                draft[rowId][colId] = parseFloat(value);
            }));
        }
    }, []);

    const getCellContent = ({ rowId, cell, rowData }) => {
        switch (cell.dataType) {
            case "TextField":
                return (
                    <DataEntryTextField
                        size="small"
                        variant="outlined"
                        inputProps={{ inputMode: "numeric" }}
                        style={{ width: 125, textAlign: "center" }}
                        value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
                        onChange={(e) =>
                            handleInputChange(
                                e,
                                rowId,
                                cell.mappingProperty,
                                cell.validations
                            )}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            {sector ?
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                    <SectorTitleTypography>{sector.title}</SectorTitleTypography>
                    <Fragment>
                        <Table>
                            <TableHead>
                                <FormTableRow>
                                    {/* The following code segment has been commented out due to the requirement of AGC-977 */}
                                    {/* <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} ></FormTableCell>
                                    {(sector.columns || {}).map((column) => (
                                        <FormTableCell sx={{ width: CELL_WIDTH[column.cssClass] }} >
                                            {column.title}
                                        </FormTableCell>
                                    ))} */}
                                </FormTableRow>
                            </TableHead>
                            <TableBody>
                                {sector.rows.map((row) => (
                                    <FormTableRow>
                                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} >
                                            {row.title}
                                        </FormTableCell>
                                        {row.cells.map((cell) => (
                                            cell.visible &&
                                            <FormTableCell sx={{ width: CELL_WIDTH[cell.cssClass] ? CELL_WIDTH[cell.cssClass] : CELL_WIDTH.GeneralCell }} >
                                                {getCellContent({
                                                    rowId: row.id,
                                                    cell: cell,
                                                    rowData: sectorDataInt && sectorDataInt[row.id]
                                                })}
                                            </FormTableCell>
                                        ))}

                                    </FormTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Fragment>
                </TableContainer>
                : <Skeleton variant="rectangular" width={'100%'} height={150} />
            }
        </>
    )
};

const ElectricityFuel = (props) => {
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const reportId = useSelector(state => state.common.reportId);
    const metaData = useSelector(state => state.electricityFuel.metaData);
    const initialWholeFarmData = useSelector(state => state.electricityFuel.initialWholeFarmData);
    const currentWholeFarmData = useSelector(state => state.electricityFuel.currentWholeFarmData);
    const initialFormData = useSelector(state => state.electricityFuel.initialFormData);
    const { successMsg, errorMsg } = useSelector(state => state.common);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);
    const {reportDetails: { reportName }} = useSelector(state => state.farmReport);

    const [formData, setFormData] = useState({})
    const [modalOpen, setModalOpen] = useState(false);
    const [nextClicked, setNextClicked] = useState(false);
    const [wholeFarmDataInt, setWholeFarmDataInt] = useState(undefined);
    const [wholeFarmDataExt, setWholeFarmDataExt] = useState(undefined);

    const sectors = metaData?.form?.sectors || [];
    const wholeFarm = metaData?.form?.wholeFarm || [];
    useEffect(() => {
        dispatch(setLoading());
        dispatch(getElectricityFuelMetaData({ reportId }));
        dispatch(getElectricityFuelFormData({ reportId }));
        dispatch(resetResponseState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(resetElectricityFuelMetaData());
        }
    }, []);

    useEffect(() => {
        const tempData = { ...initialFormData };
        setFormData(tempData);
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFormData]);

    useEffect(() => {
        setWholeFarmDataInt({ ...initialWholeFarmData });
        setWholeFarmDataExt({ ...initialWholeFarmData });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialWholeFarmData]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            successMsg && toastr.success(successMsg);
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(getElectricityFuelFormData({ reportId }));
            dispatch(resetResponseState());
            if (successMsg && nextClicked) {
                navigate('/data-entry/energy-waste/electricity-fuel-cont');
            }
            setNextClicked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        if (wholeFarmDataExt && !_.isEmpty(wholeFarmDataExt)) {
            dispatch(setWholeFarmData({
                wholeFarmData: wholeFarmDataExt
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wholeFarmDataExt]);

    const handleModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            dispatch(getElectricityFuelMetaData({ reportId }));
            dispatch(getElectricityFuelFormData({ reportId }));
        }
    }

    const isFormUpdated = () => {
        const electricityFuelData = store.getState().electricityFuel;
        const isUpdatedWholeFarmData = !isEqual(electricityFuelData?.initialWholeFarmData, electricityFuelData?.currentWholeFarmData);
        const isUpdatedEnterpriseData = !isEqual(electricityFuelData?.initialFormData, electricityFuelData?.currentFormData);
        
        return (isUpdatedWholeFarmData || isUpdatedEnterpriseData);
    }

    const handleSaveClick = (e) => {
        if(!isFormUpdated()){
            return;
        }
        dispatch(setLoading());
        dispatch(saveElectricityFuelData({ reportId }));
    }

    const handleNextClick = (e) => {
        setNextClicked(true);
        if (props.locked || !isFormUpdated())
            navigate('/data-entry/energy-waste/electricity-fuel-cont');
        else {
            dispatch(setLoading());
            dispatch(saveElectricityFuelData({ reportId }));
        };
    }

    const handleAllocateElectricityFuelClick = (props) => {
        if (currentWholeFarmData && reportId)
            dispatch(getAllocateElectricityFuel({ reportId, currentWholeFarmData }));
    }

    const handleInputChange = useCallback((e, colId, rule) => {
        let value = removeCommas(e.target.value);
        if (e.target.inputMode === "numeric") {
            let numericRegex = /^-?[0-9]*(\.[0-9]{0,2})?$/;
            if (numericRegex.test(value)) {
                if (rule.max < parseFloat(value || 0)) {
                    toastr.warning(`This input only accepts values between ${addCommas(rule.min
                    )} and ${addCommas(rule.max)}.  please enter the value again.`,
                        undefined,
                        toastrCustomOptionsLonger
                    );
                } else {
                    setWholeFarmDataInt(produce((draft) => {
                        draft[colId] = value;
                    }));
                    setWholeFarmDataExt(produce((draft) => {
                        draft[colId] = parseFloat(value);
                    }));
                }
            }
        } else {
            setWholeFarmDataInt(produce((draft) => {
                draft[colId] = value;
            }));
            setWholeFarmDataExt(produce((draft) => {
                draft[colId] = parseFloat(value);
            }));
        }
    }, []);

    return (
        <ScrollSync>
        <Grid container spacing={2} className={props.locked && 'deactivated'}>
            <Grid item xs={12} md={12} lg={12}>
                <FormHeaderPaper sx={{ p: 2, display: "flex", flexDirection: "column" }} divider={false}>
                    <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                    <SelectedReport reportName={reportName} />
                    <FormTitleTypography variant="h5" component="div">
                        Electricity & Fuel
                    </FormTitleTypography>
                    <FormDescriptionTypography variant="body2" paragraph>
                        For each applicable data category, enter the quantity of electricity and fuel used by the whole farm.
                        Quantities will prepopulate against each land use, crop and livestock enterprise.
                        Change the prepopulated values if required.
                    </FormDescriptionTypography>
                </FormHeaderPaper>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
                    <List sx={{ mb: 2 }}>
                        <ListSubheader sx={{ bgcolor: "background.paper" }}>
                            <ScrollSyncPane>
                                <>
                                <DataEntryButtonStack
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                    handleModalResponse={handleModalResponse}
                                    handleSaveClick={handleSaveClick}
                                    handleNextClick={handleNextClick}
                                />
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <FormTableRow>
                                                <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}></FormTableCell>
                                                {Object.values(wholeFarm || {}).map((column) => (
                                                    <FormTableCell key={column.id} sx={{ width: CELL_WIDTH[column.cssClass] }} >
                                                        {column.title}
                                                    </FormTableCell>
                                                ))}
                                            </FormTableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                                </>
                            </ScrollSyncPane>
                        </ListSubheader>
                        {(wholeFarmDataInt && wholeFarm) && <> <TableContainer component={Paper} sx={{ mb: 3 }}>
                            <SectorTitleTypography>Whole Farm</SectorTitleTypography>
                            <Table>
                                <TableBody>
                                    <FormTableRow>
                                        <FormTableCell>Whole Farm</FormTableCell>
                                        {Object.values(wholeFarm || {}).map((cell,index) => (
                                            <FormTableCell sx={{ width: CELL_WIDTH[cell.cssClass] }}>
                                                <DataEntryTextField
                                                    size="small"
                                                    variant="outlined"
                                                    inputProps={{ inputMode: "numeric" }}
                                                    style={{ width: 125, textAlign: "center" }}
                                                    value={wholeFarmDataInt && addCommas(wholeFarmDataInt[cell.mappingProperty])}
                                                    onChange={(e) => handleInputChange(e, cell.mappingProperty, cell.validations)}
                                                    autoFocus={props.locked === false && index === 0}
                                                />
                                            </FormTableCell>
                                        ))}
                                    </FormTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
                                <Button variant="contained" align="right" sx={{ textTransform: 'none', mb: 2 }}
                                    onClick={() => handleAllocateElectricityFuelClick()}> Allocate fuel use
                                </Button>
                            </Stack>
                        </>}
                        {formData && sectors.map((sector, index) => (
                            <>
                                <React.Fragment key={index}>
                                    <DataEntryTable
                                        key={sector.id}
                                        sector={sector}
                                        initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                                    />
                                </React.Fragment></>
                        ))}
                    </List>    
                </Paper>
            </Grid>
        </Grid >
        </ScrollSync>
    )
}

export default ElectricityFuel