import {
    AppBar, FormControl, Grid, MenuItem, Paper, Tab, Table,
    TableBody, TableContainer, TableFooter, TableHead, TableRow,Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    ResultsTableCell, ResultTablesTabs, FormTitleTypography,
    FormHeaderPaper, ResultsTableRow, FarmDetailsSelect,
    ResultsTableTitleTypography, FormDescriptionTypography, InfoTooltip, InfoTooltipForGWP,
    CustomWidthTooltip
} from '../../../components/CustomStyles/StyledComponents'
import DataChecksErrors from '../../../components/Errors/DataChecksErrors';
import { addCommas } from '../../../helpers/stringFormatHelper';
import { ResultStatus, resultsDataMap } from '../../../helpers/reportResultsHelper';
import { getEnterpriseCompareToList, getWholeFarmDetailedTable, getFarmReport,
         setLoading, setReportId, unsetLoading } from '../../../store/appAction';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { WHOLE_FARM_DETAILED_TABLES, SUMMARY_TABLE } from '../../../constants/shellTables';
import CreatePDFButton from '../../../components/Buttons/CreatePDFButton';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import _ from 'lodash';
import { Cyrb53 } from "../../../helpers/hashHelper";



const DataTable = ({ tableData, rowData }) => {
    
    const getDivDangerousInnerHTML = (text) => {
        const divText = `<div>${text}</div>`;
        return <div dangerouslySetInnerHTML={{ __html: divText }} style={{ padding: '5px' }} />
    }

    const renderTableTitleTooltip= (text) =>{

        return (
        <CustomWidthTooltip title={getDivDangerousInnerHTML(text)} placement="bottom-start"> 
        <InfoOutlinedIcon  sx={{ marginLeft: '5px', verticalAlign: 'top' , cursor: 'pointer'}} fontSize='small' /> 
        </CustomWidthTooltip> )
    }
    const renderCustomTooltip = (tooltip) => {
        return tooltip?<div dangerouslySetInnerHTML={{__html: tooltip}}/>:''
    };

    const generateCarbonStockTableHead = (tableData, rowData) => {
        const columns = _.compact([
            rowData?.showSoilCarbon && 'Soil Carbon',
            rowData?.showBiochar && 'Biochar',
            rowData?.showEstimatedBiochar && 'Estimated Biochar Sequestration*',
            'Hedges',
            'Forestry'
        ]);
        return(
            columns.map((column, idx) => (
                <ResultsTableCell key={Cyrb53(`${tableData?.tableTitle}${column}`, idx)}
                 sx={ tableData?.styles[column] || {width:'30%', fontStyle: 'none'}}>
                {<div dangerouslySetInnerHTML={{__html: column}}/>}
            </ResultsTableCell>
            ))
        );
    }

    let isCarbonStockChanges = (tableData?.tableTitle === 'Carbon Stock Changes')

    return <>
        {tableData &&
            <TableContainer sx={{ mb: 3 }}>
                <ResultsTableTitleTypography sx={{ mb: 2 }}>{tableData.tableTitle}
                { tableData?.tableTitleTooltip &&  renderTableTitleTooltip(tableData.tableTitleTooltip)}
                </ResultsTableTitleTypography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <ResultsTableCell sx={{width:'20%'}} ></ResultsTableCell>
                            {  isCarbonStockChanges && generateCarbonStockTableHead(tableData,rowData)}
                            { !isCarbonStockChanges && tableData.columns.map((column,idx) => (
                                <ResultsTableCell key={Cyrb53(`${tableData?.tableTitle}${column}`, idx)}>
                                    <InfoTooltipForGWP title={renderCustomTooltip(tableData?.tooltips?.[idx])}>
                                        <div dangerouslySetInnerHTML={{__html: column}}/>
                                    </InfoTooltipForGWP>
                                </ResultsTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    { !isCarbonStockChanges ?
                        <TableBody>
                            {resultsDataMap(rowData,tableData.noFooter,([rowId, row]) => (
                                <ResultsTableRow>
                                    {Object.entries(row).map(([cellId, cell],idx) => (
                                        <ResultsTableCell key={Cyrb53(`${tableData?.tableTitle}${cellId}${cell}`, idx)}>
                                            {cell ? addCommas(cell) : '--'}
                                        </ResultsTableCell>
                                    ))}
                                </ResultsTableRow>
                            ))}
                        </TableBody> :
                        <TableBody>
                            {resultsDataMap(rowData?.results,tableData?.noFooter,([rowId, row]) => (
                                <ResultsTableRow>
                                    {Object.entries(row).map(([cellId, cell],idx) => (
                                        <ResultsTableCell key={Cyrb53(`${cellId}${cell}`, idx)}
                                            sx={{ fontStyle: tableData?.styles?.[tableData?.columns[idx]]?.fontStyle || 'none'}}>
                                            {cell ? addCommas(cell) : '--'}
                                        </ResultsTableCell>
                                    ))}
                                </ResultsTableRow>
                            ))}
                        </TableBody>
                    }
                    {!tableData.noFooter &&
                        <TableFooter>
                            {!isCarbonStockChanges && rowData && Object.entries(rowData)
                                .slice(Object.entries(rowData).length - 1)
                                .map(([rowId, row]) => (
                                    <ResultsTableRow key={Cyrb53(`${rowId}`, rowId)}>
                                        {Object.entries(row).map(([cellId, cell],idx) => (
                                            <ResultsTableCell  key={Cyrb53(`${cellId}${cell}`, idx)}>
                                                {cell ? addCommas(cell) : '--'}
                                            </ResultsTableCell>
                                        ))}
                                    </ResultsTableRow>
                            ))}
                            {isCarbonStockChanges && rowData && Object.entries(rowData.results)
                                .slice(Object.entries(rowData.results).length - 1)
                                .map(([rowId, row]) => (
                                    <ResultsTableRow key={Cyrb53(`${rowId}`, rowId)}>
                                        {Object.entries(row).map(([cellId, cell],idx) => (
                                            <ResultsTableCell key={Cyrb53(`${cellId}${cell}`, idx)}
                                            sx={{ fontStyle: tableData?.styles?.[tableData?.columns[idx]]?.fontStyle || 'none'}} >
                                                {cell ? addCommas(cell) : '--'}
                                            </ResultsTableCell>
                                        ))}
                                    </ResultsTableRow>
                            ))}
                        </TableFooter>}
                </Table>
            </TableContainer>
        }
    </>
}

DataTable.propTypes = {
    tableData: PropTypes.object,
    rowData: PropTypes.any
};

const WholeFarmTables = () => {
    const dispatch = useDispatch();
    const detailedResults = useSelector(state => state.wholeFarm.tableDetailedResults);
    const reportId = useSelector(state => state.common.reportId);
    const reportDetails = useSelector(state => state.farmReport.reportDetails);
    const reportList = useSelector(state => state.farm.reports);
    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);
    const permissions = useSelector(state => state.auth.permissions);

    const [currentTab, setCurrentTab] = useState(0);
    const [shellTables, setShellTables] = useState([]);
    const [calcStatus, setCalcStatus] = useState(ResultStatus.NotCalculated);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getFarmReport({ reportId }));
        dispatch(getWholeFarmDetailedTable({ reportId }));
        dispatch(getEnterpriseCompareToList({ reportId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        setShellTables(currentTab === 0 ? SUMMARY_TABLE : WHOLE_FARM_DETAILED_TABLES);
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    useEffect(() => {
        setCalcStatus(reportDetails.calculationStatus);
        setTimeout(() => {
            dispatch(unsetLoading());
        }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportDetails]);

    const handleTabChange = (event, tab) => {
        setCurrentTab(tab);
        setShellTables(tab === 0 ? SUMMARY_TABLE : WHOLE_FARM_DETAILED_TABLES);
    };

    const handleReportSelect = (e) => {
        dispatch(setReportId(e.target.value));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <FormHeaderPaper
                    sx={{
                        p: 0,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName} />
                    <FormTitleTypography variant="h5" component="div">
                        Resource use & Emissions Results
                    </FormTitleTypography>
                    <FormDescriptionTypography variant="body2" paragraph>
                        {`A ${currentTab === 0 ? 'summary' : 'detailed view'} of emissions from carbon dioxide, methane and nitrous oxide for the whole farm and per enterprise is presented below.`}
                    </FormDescriptionTypography>
                    <FormDescriptionTypography variant="body2" paragraph>
                        {currentTab === 0 ?
                            'To view where the emissions came from select the Detailed tab below.' :
                            'For a higher level view of emissions please select the Summary tab below.'}
                        {' To view this information at a higher level please select Whole Farm - Charts in the menu above.'}
                    </FormDescriptionTypography>
                </FormHeaderPaper>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static">
                    <ResultTablesTabs
                        value={currentTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        variant="standard"
                        aria-label="full width tabs example"
                        autoFocus={true}
                    >
                        <Tab sx={{ textTransform: 'none' }} label="Summary" autoFocus={true} disabled={calcStatus!==ResultStatus.Completed} />
                        <Tab sx={{ textTransform: 'none' }} label="Detailed" disabled={calcStatus!==ResultStatus.Completed} />
                    </ResultTablesTabs>
                </AppBar>
            </Grid>
            <Grid item xs={12} mb={0} mt={2}>
                <Grid container direction="row">
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <FormControl
                            variant="outlined"
                            sx={{
                                width: '350px',
                                // px: 2,
                            }}>
                            <b>Report</b>
                            <FarmDetailsSelect
                                displayEmpty
                                value={reportId || ''}
                                onChange={handleReportSelect}
                            >
                                <MenuItem value=""><em>Change Report...</em></MenuItem>
                                {reportList.map((option) => (
                                    <MenuItem
                                        id={option.reportId} key={option.reportId} value={option.reportId}>{option.reportName}</MenuItem>
                                ))}
                            </FarmDetailsSelect>
                        </FormControl>
                        <InfoTooltip
                            placement="right"
                            title="Tip: If you would like to view a different report please select from this drop-down">
                            <InfoOutlinedIcon color='primary' sx={{ marginLeft: '2px', marginBottom: '5px', verticalAlign: 'bottom' }} fontSize='small' />
                        </InfoTooltip>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} sx={{ textAlign: 'right' }} >
                        {calcStatus===ResultStatus.Completed?<CreatePDFButton referrer={'whole-farm'}/>:''}
                    </Grid>
                </Grid>
            </Grid>
                {calcStatus!==ResultStatus.Completed?
                <DataChecksErrors errorList={detailedResults?.dataChecks} resultStatus={calcStatus} /> :

                <Grid item xs={12} md={12} lg={12} sx={{ minHeight: 300 }}>
                    {Object.entries(shellTables).map(([tableKey, tableData]) => (
                        <>
                            <DataTable
                                tableId={tableKey}
                                tableData={tableData}
                                rowData={detailedResults[tableKey]}
                            />
                            {
                                tableKey === 'carbonSequestrationResults' && detailedResults['carbonSequestrationResults']?.showEstimatedBiochar &&
                                <Grid item xs={12}>
                                    <Typography variant="body2" paragraph>
                                        *The carbon associated with the biochar applied has already been claimed.
                                        That means it cannot be included in your carbon footprint.
                                        The values shown in this column are to provide an indication of how much sequestration may be occurring, despite its exclusion from your carbon footprint.
                                    </Typography>
                                </Grid>
                            }
                        </>
                    ))}
                </Grid>
            }
        </Grid >
    )
}

export default WholeFarmTables