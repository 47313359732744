import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import toastr from "toastr";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { deleteProject, getFarmOwnersData, getProjectAdminData, resetResponseState, unsetLoading, validateInviteeEmail } from '../../store/appAction';
import {
    Box, Button, FormControl, Grid,
    InputLabel, MenuItem, Paper, Select,
    Stack, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelModal from '../Modal/CancelModal';
import DeleteModal from '../Modal/DeleteModal';
import { ADMIN, CONSULTANT, FARMER, SUPER_ADMIN } from '../../constants/userRoles';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { DataEntryTextField, FormTableCell, FormTableRow } from '../CustomStyles/StyledComponents';
import { EDIT } from '../../constants/modes';

const ProjectForm = props => {
    dayjs.extend(utc);
    const {
        values: {
            id,
            projectName,
            description,
            administratorId,
            createdOn,
            lastModified,
            users,
        },
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleReset,
        resetForm,
        mode
    } = props;
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [projectAdmin, setProjectAdmin] = useState(null);
    const [inviteeEmail, setInviteeEmail] = useState('');

    const user = useSelector(state => state.auth.user);
    const projectAdminAccount = useSelector(state => state.adminProject.adminAccount);
    const { successMsg, errorMsg } = useSelector(state => state.common);

    useEffect(() => {
        dispatch(getProjectAdminData((mode === EDIT) ? administratorId : user?.accountId));
        return () => {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(getProjectAdminData((mode === EDIT) ? administratorId : user?.accountId));
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                if (mode !== EDIT) {
                    handleReset();
                    setProjectAdmin(null);
                }
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        if (projectAdminAccount)
            setProjectAdmin(projectAdminAccount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectAdminAccount]);

    const handleCancelModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
           window.scrollY = 0;
            handleReset();
        }
    }

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
           window.scrollY = 0;
            handleReset();
            dispatch(deleteProject());
            handleBackResponse();
        }
    }

    const handleBackResponse = () => {
        navigate('/admin/project/list');
    }

    const addNewInvitation = async () => {
        if (inviteeEmail) {
            const { validated, error } = await dispatch(validateInviteeEmail(inviteeEmail));
            if (validated) {
                const { id, email, fullName } = validated;
                const tempUsers = [...users];
                tempUsers.push({
                    isAdded: true,
                    isInvitationPending: true,
                    id, email, fullName
                });

                setFieldValue("users", tempUsers);
            } else {
                toastr.error(error, undefined, toastrCustomOptionsLonger);
            }
            setInviteeEmail('');
        }
    }

    const deleteInvitation = (email) => {
        const tempUsers = [...users];

        let inviteeIdx = _.findIndex(
            tempUsers,
            (user) => {
                return user.email === email;
            }
        );
        if (tempUsers[inviteeIdx].isAdded)
            tempUsers.splice(inviteeIdx, 1);
        else
            tempUsers[inviteeIdx].isDeleted = true;

        setFieldValue("users", tempUsers);
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            autoFocus
                            id="projectName"
                            name="projectName"
                            label="Project Name"
                            error={Boolean(errors.projectName)}
                            onChange={handleChange}
                            value={projectName}
                            helperText={touched.projectName ? errors.projectName : ""}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            id="description"
                            name="description"
                            label="Description"
                            onChange={handleChange}
                            error={Boolean(errors.description)}
                            value={description}
                            helperText={touched.description ? errors.description : ""}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="administratorId-select">Administrator</InputLabel>
                            <Select
                                disabled
                                fullWidth
                                name="administratorId"
                                label="Administrator"
                                id="administratorId"
                                labelId="administratorId-select"
                                value={administratorId || user?.accountId}
                            >
                                <MenuItem
                                    id={projectAdmin?.id}
                                    key={projectAdmin?.id}
                                    value={projectAdmin?.id}
                                >
                                    {projectAdmin?.fullName}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                    </Grid>
                    {(mode === EDIT) &&
                        <>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    disabled
                                    fullWidth
                                    id="createdOn"
                                    name="createdOn"
                                    label="Created On"
                                    value={createdOn ? dayjs.utc(createdOn || '').local().format('DD MMM YYYY') : ''}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    disabled
                                    fullWidth
                                    id="lastModified"
                                    name="lastModified"
                                    label="Last Modified"
                                    value={lastModified ? dayjs.utc(lastModified || '').local().format('DD MMM YYYY') : ''}
                                />
                            </Grid>
                        </>}
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={1} p={1}>
                            <Grid item xs={12}>
                                <Typography variant='h6' >Users</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ width: '100%' }} size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <FormTableCell sx={{ width: '60%' }}>Email</FormTableCell>
                                                <FormTableCell
                                                    align="center"
                                                    sx={{ width: '20%' }}
                                                >
                                                    Invite
                                                </FormTableCell>
                                                <FormTableCell
                                                    align="center"
                                                    sx={{ width: '10%' }}
                                                >
                                                    Add
                                                </FormTableCell>
                                                <FormTableCell
                                                    align="center"
                                                    sx={{ width: '10%' }}
                                                >
                                                    Delete
                                                </FormTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.map((user) => {
                                                return (
                                                    <>
                                                        {!user.isDeleted &&
                                                            <FormTableRow>
                                                                <FormTableCell sx={{ width: '60%' }}>
                                                                    {user.email}
                                                                </FormTableCell>
                                                                <FormTableCell sx={{ width: '30%' }}>
                                                                    {user.isInvitationPending ? 'Pending' : 'Accepted'}
                                                                </FormTableCell>
                                                                <FormTableCell sx={{ width: '10%' }}>
                                                                </FormTableCell>
                                                                <FormTableCell sx={{ width: '10%' }}>
                                                                    <Button
                                                                        style={{ padding: '3px 8px' }}
                                                                        onClick={() => deleteInvitation(user.email)}>
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </FormTableCell>
                                                            </FormTableRow>
                                                        }
                                                    </>
                                                )
                                            })}
                                            <FormTableRow>
                                                <FormTableCell sx={{ width: '60%' }}>
                                                    <DataEntryTextField
                                                        size="small"
                                                        variant="outlined"
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        style={{ width: '98%', textAlign: 'center' }}
                                                        value={inviteeEmail}
                                                        onChange={(e) => setInviteeEmail(e.target.value)}
                                                    />
                                                </FormTableCell>
                                                <FormTableCell sx={{ width: '30%' }}>
                                                </FormTableCell>
                                                <FormTableCell sx={{ width: '10%' }}>
                                                    <Button
                                                        style={{ padding: '3px 8px' }}
                                                        onClick={addNewInvitation}>
                                                        <AddIcon />
                                                    </Button>
                                                </FormTableCell>
                                                <FormTableCell sx={{ width: '10%' }}>
                                                </FormTableCell>
                                            </FormTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Stack spacing={2} sx={{ marginLeft: 'auto', float: 'right', marginTop: 3 }} direction="row" >
                            <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                                onClick={handleBackResponse}>
                                Back
                            </Button>
                            <Button variant="outlined" sx={{ textTransform: 'none' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setModalOpen(true);
                                }}>
                                Cancel
                            </Button>
                            {(mode === EDIT) && [ADMIN, SUPER_ADMIN].includes(user?.role) &&
                                <Button variant="outlined" sx={{ textTransform: 'none' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteModalOpen(true);
                                    }}>
                                    Delete
                                </Button>}
                            <Button type="submit" variant="contained" sx={{ textTransform: 'none' }}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <CancelModal isOpen={modalOpen} handleResponse={handleCancelModalResponse} />
                <DeleteModal
                    isOpen={deleteModalOpen}
                    handleResponse={handleDeleteModalResponse}
                    value='If you delete you will lose all farm data. Are you sure you want to delete?' />
            </Box>
        </>
    )
}

export default ProjectForm