import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { resetResponseState, unsetLoading,setLoading,setCurrentAdminOrganisation , deleteOrganisation} from '../../store/appAction';
import CancelModal from '../Modal/CancelModal';
import { EDIT } from '../../constants/modes';
import { ADMIN, CONSULTANT, FARMER, SUPER_ADMIN } from '../../constants/userRoles';
import { endpoints } from '../../api/endpoints';
import * as api from '../../api/base';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import DeleteModal from '../../components/Modal/DeleteModal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const OrganisationForm = props => {
    const {
        values: {
            id,
            name,
            file,
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleReset,
        resetForm,
        mode,
        role,
        setFieldValue,
    } = props;
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    
    const { successMsg, errorMsg } = useSelector(state => state.common);
    const [modalOpen, setModalOpen] = useState(false);

    const selectedOrganization = useSelector(state => state.adminOrganisation.selectedOrganisation);

    const [modalOneOpen, setModalOneOpen] = useState(false);
    const [modalTwoOpen, setModalTwoOpen] = useState(false);
    const [modalThreeOpen, setModalThreeOpen] = useState(false);
    const [modalFourOpen, setModalFourOpen] = useState(false);
    const [modalFiveOpen, setModalFiveOpen] = useState(false);
    const [modalSixOpen, setModalSixOpen] = useState(false);
    const [modalSevenOpen, setModalSevenOpen] = useState(false);
    const [modalEightOpen, setModalEightOpen] = useState(false);
    const [ preview, setPreview] = useState(false);
    const fileInput = useRef(null);
   
    useEffect(() => {
        return () => {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                (mode !== EDIT) && resetForm();
                toastr.success(successMsg);
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        if(file){
            if(file instanceof Blob){
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () =>{ setPreview(reader?.result)}
            }else{
                setPreview(file)
            }
        }else{
            setPreview(null)
        }
    }, [file]);

    const handleCancelModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
           window.scrollY = 0;
            handleReset();
        }
    }

    const handleBackResponse = () => {
        navigate('/admin/organisations/list');
    }

    const handleDelete =async () => {
        dispatch(setLoading());
        const response = await api.get(endpoints.admin.organizations.organizationUsers.replace("ORGANIZATION_ID", selectedOrganization.id));
        if (response && response.status === 200) {
            if (response.data?.hasAnyUsers) {
                setModalOneOpen(true);
            } else {
                const response = await api.get(endpoints.admin.organizations.organizationFarms.replace("ORGANIZATION_ID", selectedOrganization.id));
                if(response && response.status === 200){
                    if(response.data?.hasAnyFarms){
                        dispatch(setCurrentAdminOrganisation(selectedOrganization));
                        setModalThreeOpen(true)
                    }else{
                        const response = await api.get(endpoints.admin.organizations.organizationOffices.replace("ORGANIZATION_ID", selectedOrganization.id));
                        if(response && response.status === 200){
                            if(response.data?.hasAnyOffices){
                                dispatch(setCurrentAdminOrganisation(selectedOrganization));
                                setModalEightOpen(true);
                            }else{
                                dispatch(setCurrentAdminOrganisation(selectedOrganization));
                                setModalSevenOpen(true);
                            }
                        }else{
                            dispatch(unsetLoading());
                            toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);      
                        }
                    }
                }else{
                    toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);
                }
                dispatch(unsetLoading());
            }
        } else {
            toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);
        }
        dispatch(unsetLoading());
    }

    const handleModalTwoResponse = async (positiveResponse) => {
        setModalTwoOpen(false);
        if (positiveResponse) {
            dispatch(setLoading());
            dispatch(deleteOrganisation());
            handleBackResponse();
        }else{
            dispatch(unsetLoading());
        }
    }

    const handleModalThreeResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalThreeOpen(false);
            dispatch(setLoading());
            const response = await api.get(endpoints.admin.organizations.organizationOffices.replace("ORGANIZATION_ID", selectedOrganization.id));
            if(response && response.status === 200){
                if(response.data?.hasAnyOffices){
                    setModalFiveOpen(true);
                }else{
                    dispatch(setCurrentAdminOrganisation(selectedOrganization));
                    setModalFourOpen(true);
                }
            }else{
                dispatch(unsetLoading());
                toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);      
            }
        }else{
            setModalThreeOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalFourResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalFourOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
            handleBackResponse();
        }else{
            setModalFourOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalFiveResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalFiveOpen(false);
            setModalSixOpen(true);
        }else{
            setModalFiveOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalSixResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalSixOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
            handleBackResponse();
        }else{
            setModalSixOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalSevenResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalSevenOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
            handleBackResponse();
        }else{
            setModalSevenOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalEightResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalEightOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
            handleBackResponse();
        }else{
            setModalEightOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleImageUploadButtonClick = (event) => {
        fileInput.current.click();
    };

    const hanleRemoveImage = (event) => {
        setPreview(null);
        setFieldValue('file', file);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const img = new Image();
                img.onload = () => {
                    if (img.width > 200 || img.height > 200) {
                        toastr.error("Image upload error: The selected image is problematic. Please ensure it is smaller than 200 x 200 pixels and in either JPG or PNG format. Kindly select a different image.", undefined, toastrCustomOptionsLonger);
                    } else {
                        setFieldValue('file', file);
                    }
                };
                img.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 1, ml: 20, pr: 30 }}>
                <Grid
                    spacing={1}
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            autoComplete="name"
                            label="Organisation Name"
                            helperText={touched.name ? errors.name : ""}
                            error={Boolean(errors.name)}
                            value={name}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                            onClick={handleImageUploadButtonClick}>
                            Choose logo
                        </Button>

                        <div>
                            <input
                                type='file'
                                name='file'
                                accept='image/*'
                                ref={fileInput}
                                style={{ display: "none" }}
                                onChange={(e) => { handleImageChange(e) }}
                            />
                        </div>
                    </Grid>
   
                    <Grid item xs={12} sm={6}>
                        {(preview) &&
                            <div style={{  position:'relative', display:'inline-block' }}>
                                <IconButton style={{ position:'absolute', top:'5px', left:'5px' }} onClick={ hanleRemoveImage }>
                                    <CloseIcon style={{ background: '#f9cdcd', borderRadius:'10px' , padding:'3px'}}/>
                                </IconButton>
                                 <img src={ preview } style={{ width:'300px', display:'block', maxWidth:'100%', height:'auto' }}/>
                            </div>
                        }
                    </Grid>
                    <Stack spacing={2} sx={{ marginLeft: 'auto', marginTop: 3 }} direction="row" >
                        <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                            onClick={handleBackResponse}>
                            Back
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalOpen(true);
                            }}>
                            Cancel
                        </Button>
                        {(mode === EDIT) && [ADMIN, SUPER_ADMIN].includes(role) &&
                            <Button variant="outlined" sx={{ textTransform: 'none' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete();
                                }}>
                                Delete
                            </Button>}
                        <Button type="submit" variant="contained" sx={{ textTransform: 'none' }}>
                            Save
                        </Button>
                    </Stack>
                </Grid>
                <CancelModal isOpen={modalOpen} handleResponse={handleCancelModalResponse} />
                <ConfirmModal
                    isOpen={modalOneOpen}
                    value='You cannot delete an organisation that has users. Please delete the users from this organisation first.'
                    handleResponse={(value)=>{
                        setModalOneOpen(false);
                        dispatch(unsetLoading());
                    }} 
                />
                <DeleteModal
                    isOpen={modalTwoOpen}
                    handleResponse={handleModalTwoResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalThreeOpen}
                    value='All farms assigned to this organisation will no longer have an organisation link. Do you wish to continue?'
                    handleResponse={(value)=>{
                        handleModalThreeResponse(value);
                    }} 
                />
                <DeleteModal
                    isOpen={modalFourOpen}
                    handleResponse={handleModalFourResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalFiveOpen}
                    handleResponse={handleModalFiveResponse}
                    value={'All offices in the organisation will be deleted. Do you wish to continue?'}
                />
                <DeleteModal
                    isOpen={modalSixOpen}
                    handleResponse={handleModalSixResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalSevenOpen}
                    handleResponse={handleModalSevenResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalEightOpen}
                    handleResponse={handleModalEightResponse}
                    value={'All offices in the organisation will be deleted. Do you wish to continue?'}
                />
            </Box>

        </>
    )
}

export default OrganisationForm